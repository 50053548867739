import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageNotFoundComponent} from '@layout/page-not-found/page-not-found.component';
import {NotificationsLayoutComponent} from '@layout/notifications/notifications.component';
import {RouterModule} from '@angular/router';
import {LayoutComponent} from '@layout/layout.component';
import {ShapeModule} from '@shape/shape.module';
import {DialogLayoutComponent} from '@layout/dialog/dialog.component';
import {DefaultLayoutComponent} from '@layout/default/default.component';
import {ConsoleLayoutComponent} from '@layout/console/console.component';
import {NakedLayoutComponent} from '@layout/naked/naked.component';
import {NotificationsModule} from '@app/notifications/notifications.module';

@NgModule({
  imports: [
    CommonModule,
    ShapeModule,
    RouterModule.forChild([]),
    NotificationsModule
  ],
  exports: [
    LayoutComponent,
    NakedLayoutComponent,
    DialogLayoutComponent
  ],
  declarations: [
    DialogLayoutComponent,
    LayoutComponent,
    NotificationsLayoutComponent,
    PageNotFoundComponent,
    DefaultLayoutComponent,
    NakedLayoutComponent,
    ConsoleLayoutComponent
  ],
})
export class LayoutModule { }
