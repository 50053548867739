import gql from 'graphql-tag';

const query = gql`
query GetLayout {
  getLayout @client {
    type
  }
}`;

export { query as GetLayout };
