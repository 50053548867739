import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {SignupDTO} from '@auth/models/signup-dto';
import {Country} from '@shape/data/countries';
import {RequireMatch} from '@shape/validators/require-match';
import {MustMatch} from '@shape/validators/must-match';

@Component({
  selector: 'shp-signup-form',
  templateUrl: './signup-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupFormComponent {
  @Input() message: string;
  @Input() submitting: boolean;
  @Input() countries: Country[];
  @Output() submitted = new EventEmitter<SignupDTO>();

  maxLengthValidation = 256;
  showPassword = false;
  showConfirmPassword = false;
  filteredCountries$: Observable<Country[]>;

  registrationForm: FormGroup = this.fb.group({
    firstName: ['', {
      validators: [Validators.required, Validators.maxLength(this.maxLengthValidation)],
      updateOn: 'blur'
    }],
    lastName: ['', {
      validators: [Validators.required, Validators.maxLength(this.maxLengthValidation)],
      updateOn: 'blur'
    }],
    username: ['', {
      validators: [Validators.required],
      updateOn: 'blur'
    }],
    email: ['', {
      validators: [Validators.required, Validators.email],
      updateOn: 'blur'
    }],
    password: ['', {
      validators: [Validators.required],
      updateOn: 'blur'
    }],
    confirmPassword: ['', {
      validators: [Validators.required],
      updateOn: 'blur'
    }],
    country: ['', RequireMatch],
    privacyPolicyAgreed: [false, Validators.requiredTrue]
  }, {
    validator: MustMatch('password', 'confirmPassword')
  });

  get firstName() { return this.registrationForm.get('firstName'); }
  get lastName() { return this.registrationForm.get('lastName'); }
  get username() { return this.registrationForm.get('username'); }
  get email() { return this.registrationForm.get('email'); }
  get password() { return this.registrationForm.get('password'); }
  get confirmPassword() { return this.registrationForm.get('confirmPassword'); }
  get country() { return this.registrationForm.get('country'); }
  get privacyPolicyAgreed() { return this.registrationForm.get('privacyPolicyAgreed'); }

  constructor(
    private fb: FormBuilder,
  ) {
    this.filteredCountries$ = this.country.valueChanges.pipe(
      startWith(''),
      map(value => this.filterCountries(value))
    );
  }

  signUp() {
    this.privacyPolicyAgreed.markAsDirty();
    if (this.registrationForm.valid) {
      const signUp = new SignupDTO(this.registrationForm.value);
      this.submitted.emit(signUp);
    } else {
      this.message = 'Please, fill in the fields in the login form';
    }
  }

  getCountryName(country: Country | string): string | undefined {
    return typeof country === 'string' ? undefined : country.name;
  }

  private filterCountries(value: Country | string): Country[] {
    if (typeof value === 'string') {
      const filterValue = value.toLowerCase();
      return this.countries.filter(country => {
        return country.name.toLowerCase().includes(filterValue);
      });
    }
  }
}
