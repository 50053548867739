import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AuthErrorMessageService {
  private _errorMessages: {[key: string]: string} = {
    // The error happens if the user didn't finish the confirmation step when signing up
    // In this case you need to resend the code and confirm the user
    // About how to resend the code and confirm the user, please check the signUp part
    'UserNotConfirmedException': 'Sorry, wrong credentials provided',
    // The error happens when the password is reset in the Cognito console
    // In this case you need to call forgotPassword to reset the password
    // Please check the Forgot Password part.
    'PasswordResetRequiredException': 'Sorry, wrong credentials provided',
    // The error happens when the incorrect password is provided
    'NotAuthorizedException': 'Sorry, wrong credentials provided',
    // The error happens when the supplied username/email does not exist in the Cognito user pool
    'UserNotFoundException': 'Sorry, wrong credentials provided',
  };

  message(code: string): string {
    return this._errorMessages[code];
  }
}
