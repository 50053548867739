import gql from 'graphql-tag';

const mutation = gql`
    mutation DeleteUserNotification($input: DeleteUserNotificationInput!) {
        deleteUserNotification(input: $input) {
            __typename
            id
        }
    }`;

export {mutation as DeleteUserNotification};
