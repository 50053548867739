import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {RouterModule} from '@angular/router';
import {InlineSVGModule} from 'ng-inline-svg';
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {
  GestureConfig,
  MatButtonModule,
  MatIconModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatTooltipModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';

const MAT_MODULES = [
  MatButtonModule,
  MatSliderModule,
  MatProgressBarModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  DragDropModule,
  MatIconModule,
  MatSlideToggleModule,
];

@NgModule({
  imports: [
    ...MAT_MODULES,
    CommonModule,
    InlineSVGModule.forRoot(),
    RouterModule,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
  ],
  exports: [
    ...MAT_MODULES,
    InlineSVGModule
  ]
})
export class LibPlayerUiModule {}
