import {from, Observable} from 'rxjs';
import {ApolloQueryResult} from 'apollo-client';
import {map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import GetLastErrorResponse from '@core/graphql/get-last-error/get-last-error-response';
import {GetLastError} from '@core/graphql/get-last-error/get-last-error.local';
import {UpdateLastError} from '@core/graphql/update-last-error/update-last-error.local';
import {ShapeError} from '@shape/models/shape-error';
import {AppsyncService} from '@shape/appsync.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  constructor(private appsyncService: AppsyncService) { }

  private lastErrorSubscription;

  watchLastError(): Observable<ShapeError> {
    return from(this.appsyncService.client).pipe(
      switchMap(client => this.fromLastErrorObservableQuery(
        client.watchQuery<GetLastErrorResponse>({
          query: GetLastError
        })
      )),
      map(({data}) => new ShapeError(data.getLastError))
    );
  }

  unwatchLastError(): void {
    if (this.lastErrorSubscription) {
      this.lastErrorSubscription.unsubscribe();
    }
  }

  updateLastError(error: ShapeError): void {
    this.appsyncService.client.then(client => {
      client.mutate({
        mutation: UpdateLastError,
        variables: {...error}
      });
    });
  }

  private fromLastErrorObservableQuery(observableQuery): Observable<ApolloQueryResult<GetLastErrorResponse>> {
    return new Observable<ApolloQueryResult<GetLastErrorResponse>>(observer => {
      this.lastErrorSubscription = observableQuery.subscribe(
        result => observer.next(result),
        error => observer.error(error)
      );
      return this.lastErrorSubscription;
    });
  }
}
