import {EditShapeCommand} from '@editor/models/edit-shape-command';
import {Shape} from '@lib/models/shape';

/**
 * When you create a new command, remember to add its type into Class "EditorApiService"
 * */
export class EditTitle extends EditShapeCommand {
  title: string;

  constructor(init: Partial<EditTitle>) {
    super(init);
  }

  get payload(): string {
    return JSON.stringify({
      title: this.title
    });
  }

  apply(shape: Shape): Shape {
    return new Shape({
      ...shape,
      title: this.title
    });
  }
}
