import {Component, ChangeDetectionStrategy} from '@angular/core';
import {AuthApiService} from '@auth/auth-api.service';
import {Subject} from 'rxjs';
import {CompleteDTO} from '@auth/models/complete-dto';

@Component({
  selector: 'shp-complete',
  template: `
    <shp-privacy-policy></shp-privacy-policy>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivacyComponent {
  errorMessage = new Subject<string>();

  constructor(private authApi: AuthApiService) {}
}
