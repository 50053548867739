import {Injectable, OnDestroy} from '@angular/core';
import {AppsyncService} from '@shape/appsync.service';
import {ErrorsService} from '@shape/errors.service';
import {combineLatest, from, Observable, of, Subject} from 'rxjs';
import {Notification} from '@app/notifications/models/notification';
import {catchError, distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ApolloQueryResult} from 'apollo-client';
import ListUserNotificationsResponse from '@app/notifications/graphql/querys/list-my-notifications-response';
import {ListUserNotifications} from '@app/notifications/graphql/querys/list-my-notifications';
import UpdateUserNotificationInput from '@app/notifications/graphql/mutations/update-user-notification/update-user-notification-input';
import {UpdateUserNotification} from '@app/notifications/graphql/mutations/update-user-notification/update-user-notification';
// tslint:disable-next-line:max-line-length
import UpdateUserNotificationResponse from '@app/notifications/graphql/mutations/update-user-notification/update-user-notification-response';
// tslint:disable-next-line:max-line-length
import DeleteUserNotificationResponse from '@app/notifications/graphql/mutations/delete-user-notification/delete-user-notification-response';
import DeleteUserNotificationInput from '@app/notifications/graphql/mutations/delete-user-notification/delete-user-notification-input';
import {DeleteUserNotification} from '@app/notifications/graphql/mutations/delete-user-notification/delete-user-notification';
import {ShapeErrorType} from '@shape/models/shape-error-type';
import {AuthApiService} from '@auth/auth-api.service';
import {ShapeError} from '@shape/models/shape-error';
import SubscriptionUserNotificationsResponse from '@app/notifications/graphql/subscriptions/on-user-notification-update-response';
import {OnUserNotificationUpdate} from '@app/notifications/graphql/subscriptions/on-user-notification-update';
import {NotificationStatus} from '@app/notifications/models/notification-status.enum';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService implements OnDestroy {

  constructor(
    private appsyncService: AppsyncService,
    private errorsService: ErrorsService,
    private authApi: AuthApiService
  ) {
    this.subscribeUserNotificationUpdate().pipe(takeUntil(this.unsubscribe$)).subscribe();
    this.listNotifications$ = this.watchListMyNotification();
    this.numNewNotifications$ = this.listNotifications$.pipe(
      map((userNotifications) => {
        if (userNotifications) {
          return userNotifications.filter(not => not.status === NotificationStatus.NEW).length +
            userNotifications.filter(not => not.status === NotificationStatus.UNREAD).length;
        }
        return null;
      })
    );
  }
  listNotifications$: Observable<Notification[]>;
  numNewNotifications$: Observable<number>;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private listUserNotificationSubscription;
  private realtimeUserNotificationSubscription;

  ngOnDestroy(): void {
    if (this.realtimeUserNotificationSubscription) {
      this.realtimeUserNotificationSubscription.unsubscribe();
    }
    if (this.listUserNotificationSubscription) {
      this.listUserNotificationSubscription.unsubscribe();
    }
  }

  /** Get notifications stored */
  private watchListMyNotification(): Observable<Notification[]> {
    return combineLatest(this.authApi.user$, from(this.appsyncService.client)).pipe(
      switchMap( ([user, client]) => this.fromMyNotificationObservableQuery(
        client.watchQuery<ListUserNotificationsResponse>({
          query: ListUserNotifications,
          fetchPolicy: 'network-only'
        })
      )),
      filter(({loading}) => !loading),
      map(({data}) => {
        return data.listUserNotifications.items.map(itemNotification => {
          return new Notification({ ...itemNotification });
        });
      }),
    );
  }
  /** Subscription in order to receive realTime notification from the system */
  private subscribeUserNotificationUpdate(): Observable<Notification> {
    return combineLatest(this.authApi.user$, from(this.appsyncService.client)).pipe(
      switchMap(([user, client]) => this.fromUserShapesStatusUpdateSubscription(
        client.subscribe<SubscriptionUserNotificationsResponse>({
          query: OnUserNotificationUpdate,
          variables: {
            recipient: user.getUsername()
          },
        }),
      )),
      tap(async (userNotification) => this.onCreateNotification(userNotification)),
      catchError(this.onUserNotificationError.bind(this, ShapeErrorType.GRAPHQL))
    );
  }

  async updateUserNotification(userNotificationUpdated: Notification): Promise<void> {
    const input: UpdateUserNotificationInput = {
      id: userNotificationUpdated.id,
      status: userNotificationUpdated.status
    };
    (await this.appsyncService.client).mutate<UpdateUserNotificationResponse, { input: UpdateUserNotificationInput }>({
      mutation: UpdateUserNotification,
      variables: {input: input},
      optimisticResponse: () => ({updateUserNotification: { __typename: 'UserNotification', ...input }}),
    });
  }

  async deleteUserNotification(userNotificationUpdated: Notification): Promise<void> {
    const input: UpdateUserNotificationInput = {
      id: userNotificationUpdated.id
    };
    (await this.appsyncService.client).mutate<DeleteUserNotificationResponse, { input: DeleteUserNotificationInput }>({
      mutation: DeleteUserNotification,
      variables: {input: input},
      optimisticResponse : () => ({
        deleteUserNotification: {
          __typename: 'UserNotification',
          id: input.id
        }
      }),
      update: (proxy, {data: {deleteUserNotification: userNotification}}) => this.onDeleteNotification(proxy, userNotification)
    });
  }

  async onCreateNotification(userNotification) {
    if (userNotification && userNotification.data && userNotification.data.onUserNotificationUpdate) {
      const client = (await this.appsyncService.client);
      const cachedResponse: ListUserNotificationsResponse = client.readQuery({query: ListUserNotifications});
      const items = [
        userNotification.data.onUserNotificationUpdate,
        ...cachedResponse.listUserNotifications.items
      ];
      const listUserNotifications = {
        listUserNotifications: {
          __typename: 'ModelUserNotificationConnection',
          items: items,
          nextToken: null
        }
      };
      client.writeQuery({query: ListUserNotifications, data: listUserNotifications});
    }
  }

  onDeleteNotification(cache, userNotification) {
    if (userNotification && userNotification.id) {
      const cachedResponse: ListUserNotificationsResponse = cache.readQuery({query: ListUserNotifications});
      const items = cachedResponse.listUserNotifications.items.filter(item => item.id !== userNotification.id);

      const listUserNotifications = {
        listUserNotifications: {
          __typename: 'ModelUserNotificationConnection',
          items: items,
          nextToken: null
        }
      };
      cache.writeQuery({query: ListUserNotifications, data: listUserNotifications});
    }
  }

  /** translate apollo observale in rxjs observable */
  private fromMyNotificationObservableQuery(observableQuery): Observable<ApolloQueryResult<ListUserNotificationsResponse>> {
    return new Observable<ApolloQueryResult<ListUserNotificationsResponse>>(observer => {
      this.listUserNotificationSubscription = observableQuery.subscribe(
        result => observer.next(result),
        error => observer.error(error)
      );
      return this.listUserNotificationSubscription;
    });
  }
  /** translate apollo observale in rxjs observable */
  private fromUserShapesStatusUpdateSubscription(apolloObservable: any): Observable<SubscriptionUserNotificationsResponse> {
    return new Observable<SubscriptionUserNotificationsResponse>(observer => {
      const subscription = apolloObservable.subscribe(
        result => observer.next(result),
        error => observer.error(error)
      );
      this.realtimeUserNotificationSubscription = subscription;
      return subscription;
    });
  }

  private onUserNotificationError(type: ShapeErrorType, output: any, error: { message: string }): Observable<any> {
    if (error.message) {
      const shapeError = new ShapeError({message: error.message, type: type});
      this.errorsService.updateLastError(shapeError);
    }
    return of(output);
  }
}
