import gql from 'graphql-tag';

const mutation = gql`
mutation UpdateLastError($message: String!, $type: ShapeErrorType) {
  updateLastError(message: $message, type: $type) @client {
    message
    type
  }
}`;

export { mutation as UpdateLastError };
