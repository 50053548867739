import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';
import {AuthApiService} from '@auth/auth-api.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GuestGuardService implements CanActivate {
  constructor(private authApi: AuthApiService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authApi.authenticated$.pipe(
        tap(authenticated => {
          if (authenticated) {
            this.router.navigate(['/dashboard']);
          }
        }),
        map(authenticated => !authenticated)
      );
  }
}
