import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {FroalaEditorModule, FroalaViewModule} from 'angular-froala-wysiwyg';
import {RouterModule} from '@angular/router';
import {InlineSVGModule} from 'ng-inline-svg';
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {
  GestureConfig,
  MatAutocompleteModule,
  MatButtonModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatMenuModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule
} from '@angular/material';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {TimeAgoPipe} from 'time-ago-pipe';

const MAT_MODULES = [
  MatButtonModule,
  MatToolbarModule,
  MatInputModule,
  MatSelectModule,
  MatFormFieldModule,
  MatAutocompleteModule,
  MatMenuModule,
  MatSliderModule,
  MatDialogModule,
  MatProgressBarModule,
  MatRippleModule,
  MatTabsModule,
  MatProgressSpinnerModule,
  MatTooltipModule,
  DragDropModule,
  MatRadioModule,
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatChipsModule,
  MatIconModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSidenavModule,
];

@NgModule({
  declarations: [
    TimeAgoPipe
  ],
  imports: [
    ...MAT_MODULES,
    ReactiveFormsModule,
    CommonModule,
    FroalaEditorModule.forRoot(),
    FroalaViewModule.forRoot(),
    InlineSVGModule.forRoot(),
    RouterModule,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG, useClass: GestureConfig },
  ],
  exports: [
    ...MAT_MODULES,
    FroalaEditorModule,
    FroalaViewModule,
    InlineSVGModule,
    TimeAgoPipe,
  ]
})
export class ShapeUiModule {}
