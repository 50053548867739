import {AfterViewInit, Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'shp-dialog-layout',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogLayoutComponent implements AfterViewInit, OnInit {
  @ViewChild('vc', {static: false, read: ViewContainerRef}) vc: ViewContainerRef;
  dynamicComponent: any = null;
  clickClose = true;
  keyboardEscClose = true;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
  ) { }

  ngOnInit(): void {
    this.dynamicComponent = (this.route.snapshot.data.component) ? this.route.snapshot.data.component : null;
    this.clickClose = (this.route.snapshot.data.clickClose !== false);
    this.keyboardEscClose = (this.route.snapshot.data.keyboardEscClose !== false);
  }

  ngAfterViewInit() {
    if (this.vc && this.dynamicComponent) {
      this.loadComponent();
    }
  }
  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.dynamicComponent);
    /** @TODO remove setTimeout, now is necessary because when serve the app,
     * in console appears an error 'ExpressionChangedAfterItHasBeenCheckedError'
     * */
    setTimeout(() => {
      this.vc.clear();
      this.vc.createComponent(componentFactory);
    }, 0);
  }

  clickOverlay() {
    if(this.clickClose) {
      this.router.navigate(['', {outlets: {popup: null}}]);
    }
  }

  navigateTo(tabName: string) {
    this.router.navigate([tabName], { relativeTo: this.route });
  }
}
