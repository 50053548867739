import {Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {PlayerOptions} from '@lib/models/player-options';
import {debounceTime, filter, map, takeUntil} from 'rxjs/operators';

export interface OutputPlayerSize {
  base?: {
    width: number;
    height: number;
  }
  width?: number;
  height?: number;
  scale?: number;
}
interface AspectRatio {
  width: number;
  height: number;
}

@Directive({
  selector: '[shpScalable]',
})
export class ScaleContentDirective implements OnInit, OnDestroy {
  @Input('shpScalable') active = true;
  @Input() options: PlayerOptions = new PlayerOptions();
  @Input() parent: HTMLDivElement;
  @Output() size = new EventEmitter<OutputPlayerSize>();
  private dimension: AspectRatio;
  private containerSize: OutputPlayerSize = {scale: 1};
  private unsubscribe$ = new Subject<void>();

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    if (this.active) {
      this.dimension = {
        width: this.options.width,
        height: this.options.height
      };
      this.scaleContent();
      fromEvent(window, 'resize').pipe(
        debounceTime(100),
        map(() => {
          if (this.parent.clientWidth > 0 && this.containerSize.scale !== this.parent.clientWidth / this.dimension.width) {
            return {
              width: this.parent.clientWidth,
              height: this.parent.clientHeight
            };
          }
          return null;
        }),
        filter((size) => size !== null),
        takeUntil(this.unsubscribe$)
      ).subscribe(() => {
        this.scaleContent();
      });
    }
  }

  scaleContent(): void {
    let scale = 1;
    if (this.options.scalable) {
      try {
        scale = this.parent.clientWidth / this.dimension.width;
      } catch (e) {}
    }
    this.parent.style.height = `${this.dimension.height * scale}px`;
    this.el.nativeElement.style.width = `${this.dimension.width}px`;
    this.el.nativeElement.style.height = `${this.dimension.height}px`;
    this.el.nativeElement.style.transform = `translate(-50%, -50%) scale(${scale})`;
    this.containerSize = {
      base: {
        width: this.dimension.width,
        height: this.dimension.height,
      },
      width: this.parent.clientWidth,
      height: this.parent.clientHeight,
      scale
    };
    this.size.emit(this.containerSize);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
