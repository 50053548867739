import gql from 'graphql-tag';

const query = gql`
  query GetShape($id: ID!) {
    getShape(id: $id) {
      __typename
      id
      cover {
        __typename
        region
        bucket
        key
      }
      title
      status
      statusInfo
      body
      editCommands(limit: 1000) {
        __typename
        items {
          __typename
          type
          payload
          createdAt
        }
        nextToken
      }
      transcriptFile {
        __typename
        region
        bucket
        key
      }
    lastPublishedUrl
    createdAt
    updatedAt
  }
}`;

export { query as GetShape };
