import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
/*
 * Takes an username and return the initials (max: 2 letters)
 * Usage:
 *   value | formatTime
*/
@Pipe({name: 'formatTime'})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const duration = moment.duration(value * 1000);
    let returnvalue = '';
    const hours = duration.hours();
    returnvalue += (hours > 0) ? (hours < 10) ? `0${hours}h ` : `${hours}h ` : '';
    const minutes = duration.minutes();
    returnvalue +=
      (minutes === 0 && !returnvalue) ?
        '' :
        (minutes < 10 && minutes > 0) ?
          (returnvalue) ? `0${minutes}m ` :  `${minutes}m ` :
          `${minutes}m `;
    const seconds = duration.seconds();
    returnvalue += (seconds < 10) ? (returnvalue) ? `0${minutes}s` :  `${seconds}s` : `${seconds}s`;
    return returnvalue;
  }
}
