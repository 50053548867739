import {Injectable} from '@angular/core';
import {Auth} from 'aws-amplify';
import awsConfig from '../../aws-exports';
import * as localForage from 'localforage';
import {IntrospectionFragmentMatcher} from 'apollo-cache-inmemory';
import AWSAppSyncClient, {AUTH_TYPE, createAppSyncLink, createLinkWithCache} from 'aws-appsync/lib';
import {ApolloLink} from 'apollo-link';
import {withClientState} from 'apollo-link-state';
import gql from 'graphql-tag';
import {Layout} from '@layout/models/layout';
import {LayoutType} from '@layout/models/layout-type';

@Injectable({
  providedIn: 'root',
})
export class AppsyncService {
  unhydratedClient: AWSAppSyncClient<any>;

  constructor() {
    const localStateLink = createLinkWithCache(cache => {
      return withClientState({
        cache,
        typeDefs: AppsyncService.getLocalTypeDefs(),
        resolvers: AppsyncService.getLocalStateResolvers(),
        defaults: AppsyncService.getLocalStateDefaults()
      });
    });
    const appSyncLink = createAppSyncLink(this.getClientConfig());
    const link = ApolloLink.from([localStateLink, appSyncLink]);

    this.unhydratedClient = new AWSAppSyncClient({
      ...this.getClientConfig(),
      offlineConfig: {
        storage: localForage
      },
      cacheOptions: {
        fragmentMatcher: AppsyncService.getFragmentMatcher()
      }
    }, {link});
  }

  private static getFragmentMatcher(): IntrospectionFragmentMatcher {
    return new IntrospectionFragmentMatcher({
      introspectionQueryResultData: {__schema: {types: []}}
    });
  }

  private static getLocalStateResolvers() {
    return {
      Mutation: {
        updateLastError: (_, {message, type}, {cache}) => {
          const data = {
            getLastError: {
              __typename: 'LastError',
              type: type,
              message
            }
          };
          cache.writeData({data});
          return null;
        },
        updateLayout: (_, {type}, {cache}) => {
          const data = {
            getLayout: {
              __typename: 'Layout',
              type: type
            }
          };
          cache.writeData({data});
          return null;
        }
      }
    };
  }

  private static getLocalStateDefaults() {
    return {
      getLastError: {
        __typename: 'LastError',
        message: '',
        type: null
      },
      getLayout: {
        __typename: 'Layout',
        type: LayoutType.NAKED
      }
    };
  }

  private static getLocalTypeDefs() {
    return gql`
      enum ShapeErrorType {
        GRAPHQL
        SHAPE_NOTFOUND
      }
      enum LayoutType {
        DEFAULT
        NAKED
        CONSOLE
      }
    `;
  }

  get client(): Promise<AWSAppSyncClient<any>> {
    return this.unhydratedClient.hydrated();
  }

  getClientConfig() {
    return {
      url: awsConfig.aws_appsync_graphqlEndpoint,
      region: awsConfig.aws_project_region,
      auth: {
        type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
        jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken()
      },
      userBucket: awsConfig.aws_user_files_s3_bucket,
      userBucketRegion: awsConfig.aws_user_files_s3_bucket_region,
      complexObjectsCredentials: () => Auth.currentCredentials()
    };
  }
}
