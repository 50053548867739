import {BehaviorSubject, combineLatest, Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Shape} from '@lib/models/shape';
import {filter, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {EditorApiService} from '@editor/editor-api.service';
import {EditShapeCommand} from '@editor/models/edit-shape-command';
import {Scene} from '@lib/models/scene';
import {Theme, themes} from '@lib/models/theme';
import {PublisherApiService} from '@app/publisher/publisher-api.service';
import {ShapeApiService} from '@shape/shape-api.service';

@Injectable({
  providedIn: 'root',
})
export class EditorService {
  editingShape$: Observable<Shape>;
  editingShapeScenes$: Observable<Scene[]>;
  editingScene$: Observable<Scene>;
  scenesCount$: Observable<number>;
  editingShapeTheme$: Observable<Theme>;

  private loadedShape$ = new BehaviorSubject<Shape>(null);
  private editCommands$ = new BehaviorSubject<EditShapeCommand[]>([]);
  private selectedSceneIndex$ = new BehaviorSubject<number>(null);
  private unsubscribe$ = new Subject<void>();

  constructor(
    private editorApi: EditorApiService,
    private shapeApi: ShapeApiService,
  ) {
    this.editingShape$ = combineLatest(this.loadedShape$, this.editCommands$).pipe(
      filter(([shape]) => !!shape),
      map(([shape, commands]) => {
        return commands.reduce((updatedShape, command) => command.apply(updatedShape), shape);
      }),
      shareReplay(1)
    );

    this.editingShapeScenes$ = this.editingShape$.pipe(
      map(shape => JSON.parse(shape.body)),
      map(body => {
        return body.scenes && body.scenes.length ? this.parseScenes(body.scenes) : [];
      }),
      shareReplay(1)
    );

    this.scenesCount$ = this.editingShapeScenes$.pipe(
      map(scenes => scenes.length),
      shareReplay(1)
    );

    this.editingScene$ = combineLatest(this.editingShapeScenes$, this.selectedSceneIndex$).pipe(
      map(([scenes, selectedIndex]) => scenes[selectedIndex]),
      shareReplay(1)
    );

    this.editingShapeTheme$ = this.editingShape$.pipe(
      filter(shape => !!shape.body),
      map(shape => JSON.parse(shape.body)),
      filter(body => !!body.theme),
      map(body => themes.find(theme => theme.id === body.theme.id)),
      shareReplay(1)
    );
  }

  startEditSession(shapeId: string): Observable<Shape> {
    return this.shapeApi.watchShape(shapeId).pipe(
      filter(shape => shape !== null),
      tap(shape => this.loadedShape$.next(shape)),
    );
  }

  async stopEditSession(): Promise<boolean> {
    this.loadedShape$.next(null);
    this.editCommands$.next([]);
    this.shapeApi.unwatchShape();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    return true;
  }

  async pushEditCommand(editCommand: EditShapeCommand): Promise<void> {
    return new Promise(resolve => {
      const shape = this.loadedShape$.getValue();
      this.editorApi.createEditShapeCommand(editCommand, shape).pipe(
        takeUntil(this.unsubscribe$),
        filter(command => command !== null)
      ).subscribe(command => {
        const commands = this.editCommands$.getValue();
        this.editCommands$.next([ ...commands, command ]);
        resolve();
      });
    });
  }

  selectScene(index: number): void {
    this.selectedSceneIndex$.next(index);
  }

  get selectedSceneIndex(): Observable<number> {
    return this.selectedSceneIndex$.asObservable();
  }

  get editingShapeId(): Observable<string> {
    return this.editingShape$.pipe(
      map(shape => shape.id)
    );
  }

  private parseScenes(scenes: Scene[]): Scene[] {
    let timeToStart = 0;
    return scenes.map((scene, index) => {
      scene = new Scene({
        ...scene,
        duration: (scene.duration < 5) ? 5 : scene.duration,
        first: index === 0,
        last: index === scenes.length - 1,
        time: {
          start: timeToStart
        }
      });
      timeToStart = scene.time.end;
      return scene;
    });
  }
}
