import {Component} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {slideUpAnimation} from '@core/animations/slideup-animation';
import {transition, trigger} from '@angular/animations';

@Component({
  selector: 'shp-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slideUpAnimation,
    trigger('blockInitialRenderAnimation', [transition( ':enter', [])])
  ]
})
export class AppComponent {
  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
