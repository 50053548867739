import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {fromEvent} from 'rxjs';
import {debounceTime, first} from 'rxjs/operators';

@Component({
  selector: 'shp-player-seekbar',
  templateUrl: './seekbar.component.html',
  styleUrls: ['./seekbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SeekbarComponent implements OnChanges {
  @Input() value = 0;
  @Input() time = 0;
  @Input() duration: number;

  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() onChangePreview: EventEmitter<any> = new EventEmitter();

  moving = false;

  videoTime = 0;
  previewTime = 0;

  constructor(private el: ElementRef) {
    fromEvent(el.nativeElement, 'mouseenter').pipe(
      debounceTime(500)
    ).subscribe((e) => {});


    fromEvent(el.nativeElement, 'mousedown').subscribe((e) => {
      this.moving = true;
      fromEvent(window, 'mouseup').pipe(first()).subscribe((e) => {
        if (this.moving) {
          this.moving = false;
          this.onChange.emit(this.previewTime);
        }
      });
    });
  }

  changeValue(value) {
    this.onChangePreview.emit(value);
    this.previewTime = value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.time && changes.time.currentValue !== changes.time.previousValue) {
      if (this.moving === false) {
        this.videoTime = changes.time.currentValue;
      }
    }
    if (changes && changes.duration && changes.duration.currentValue !== changes.duration.previousValue && changes.time) {
      this.videoTime = changes.time.currentValue;
    }
  }
}
