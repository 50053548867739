export enum ShapeStatus {
  CREATED = 'CREATED',
  BUILDING = 'BUILDING',
  BUILDING_TRANSCRIBED = 'BUILDING_TRANSCRIBED',
  BUILDING_SCENES_SPLIT = 'BUILDING_SCENES_SPLIT',
  BUILDING_SCENES_DRIED = 'BUILDING_SCENES_DRIED',
  BUILDING_SCENES_TAGGED = 'BUILDING_SCENES_TAGGED',
  BUILDING_SCENES_ENRICHED = 'BUILDING_SCENES_ENRICHED',
  DRAFT = 'DRAFT',
  BUILDING_FAILURE = 'BUILDING_FAILURE',
  ARCHIVED = 'ARCHIVED',
}

export namespace ShapeStatus {
  export function getProgress(status: ShapeStatus): number {
    switch (status) {
      case ShapeStatus.CREATED:
        return 0;
      case ShapeStatus.BUILDING:
        return 5;
      case ShapeStatus.BUILDING_TRANSCRIBED:
        return 20;
      case ShapeStatus.BUILDING_SCENES_SPLIT:
        return 35;
      case ShapeStatus.BUILDING_SCENES_DRIED:
        return 50;
      case ShapeStatus.BUILDING_SCENES_TAGGED:
        return 65;
      case ShapeStatus.BUILDING_SCENES_ENRICHED:
        return 80;
      case ShapeStatus.DRAFT:
        return 100;
    }
    return 0;
  }
  export function getProgressText(status: ShapeStatus): string {
    switch (status) {
      case ShapeStatus.CREATED:
        return 'Creating';
      case ShapeStatus.BUILDING:
        return 'Building';
      case ShapeStatus.BUILDING_TRANSCRIBED:
        return 'Transcribing';
      case ShapeStatus.BUILDING_SCENES_SPLIT:
        return 'Splitting scenes';
      case ShapeStatus.BUILDING_SCENES_DRIED:
        return 'Drying scenes';
      case ShapeStatus.BUILDING_SCENES_TAGGED:
        return 'Enriching scenes';
      case ShapeStatus.BUILDING_SCENES_ENRICHED:
        return 'Finalizing';
      case ShapeStatus.DRAFT:
        return 'Shape built';
      case ShapeStatus.BUILDING_FAILURE:
        return 'Build failed';
    }
    return '';
  }
}
