import gql from 'graphql-tag';

export const OnUserNotificationUpdate = gql`
    subscription OnUserNotificationUpdate($recipient: String!) {
        onUserNotificationUpdate(recipient: $recipient) {
            __typename
            id
            recipient
            status
            body
            createdAt
            updatedAt
        }
    }`;
