import {Component, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ErrorsService} from '@shape/errors.service';
import {ShapeError} from '@shape/models/shape-error';
import {Layout} from '@layout/models/layout';
import {LayoutService} from '@layout/layout.service';
import {LayoutType} from '@layout/models/layout-type';
import {AuthApiService} from '@auth/auth-api.service';
import {Router} from '@angular/router';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {Notification} from '@app/notifications/models/notification';
import {NotificationApiService} from '@app/notifications/notification-api.service';

@Component({
  selector: 'shp-layout',
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnDestroy {
  error$: Observable<ShapeError>;
  layout$: Observable<Layout>;
  user$: Observable<CognitoUser>;
  myNotifications$: Observable<Notification[]>;
  numNewNotifications$: Observable<number>;
  LayoutType = LayoutType;

  authenticated$: Observable<boolean>;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private authApi: AuthApiService,
    private errorsService: ErrorsService,
    private layoutService: LayoutService,
    private router: Router,
    private notificationApi: NotificationApiService
  ) {
    this.error$ = errorsService.watchLastError();
    this.layout$ = layoutService.watchLayout();
    this.authenticated$ = authApi.authenticated$;
    this.user$ = authApi.user$;

    this.myNotifications$ = this.notificationApi.listNotifications$;
    this.numNewNotifications$ = this.notificationApi.numNewNotifications$;
  }

  signOut(): Promise<void> {
    return this.authApi.signOut().then(() => {
      this.router.navigate(['/']);
    });
  }

  ngOnDestroy(): void {
    this.errorsService.unwatchLastError();
    this.layoutService.unwatchLayout();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
