import {Component, ChangeDetectionStrategy} from '@angular/core';
import {AuthApiService} from '@auth/auth-api.service';
import {Subject} from 'rxjs';
import {ConfirmDTO} from '@auth/models/confirm-dto';

@Component({
  selector: 'shp-confirm',
  template: `
    <shp-confirm-form
      (submitted)="onSubmitted($event)"
      [message]="errorMessage | async">
    </shp-confirm-form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmComponent {
  errorMessage = new Subject<string>();

  constructor(private authApi: AuthApiService) { }

  onSubmitted(data: ConfirmDTO) {
    this.authApi.confirm(data)
      .catch(reason => this.errorMessage.next(reason));
  }
}
