import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter, Input, OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import {NotificationApiService} from '@app/notifications/notification-api.service';
import {map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {NotificationStatus} from '@app/notifications/models/notification-status.enum';
import {BehaviorSubject, combineLatest, fromEvent, Observable, Subject} from 'rxjs';
import {Notification} from '@app/notifications/models/notification';
import {MatDrawer} from '@angular/material';

@Component({
  selector: 'shp-notifications-layout',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationsLayoutComponent implements OnDestroy {
  @Input() notifications: Notification[] = [];
  @Input() numNewNotifications = 0;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();
  constructor(
    private notificationApi: NotificationApiService
  ) { }

  ngOnDestroy(): void {}

  async markAllAsRead(notifications) {
    for (const updateNotification of notifications.filter(not => not.status !== NotificationStatus.READ)) {
      updateNotification.status = NotificationStatus.READ;
      await this.notificationApi.updateUserNotification(updateNotification);
    }
  }

  async markAllAsUnread(notifications) {
    for (const updateNotification of notifications.filter(not => not.status === NotificationStatus.READ)) {
      updateNotification.status = NotificationStatus.UNREAD;
      await this.notificationApi.updateUserNotification(updateNotification);
    }
  }

  async deleteAll(notifications) {
    for (const updateNotification of notifications) {
      await this.notificationApi.deleteUserNotification(updateNotification);
    }
  }
}
