import {Shape} from '@lib/models/shape';
import {Scene} from '@lib/models/scene';
import {EditShapeSceneCommand} from '@editor/models/edit-shape-scene-command';

/**
 * When you create a new command, remember to add its type into Class "EditorApiService"
 **/
export class NewScene extends EditShapeSceneCommand {
  afterSceneIndex: number;
  text?: string;

  constructor(init: Partial<NewScene>) {
    super(init);
  }

  get payload(): string {
    return JSON.stringify({
      sceneId: this.sceneId,
      afterSceneIndex: this.afterSceneIndex,
      text: this.text
    });
  }

  apply(shape: Shape): Shape {
    try {
      const body = JSON.parse(shape.body);
      const scenes = [
        ...body.scenes.slice(0, this.afterSceneIndex + 1),
        new Scene({id: this.sceneId, duration: 5, text: this.text}),
        ...body.scenes.slice(this.afterSceneIndex + 1),
      ];
      return new Shape({
        ...shape,
        body: JSON.stringify({
          ...body,
          scenes
        })
      });
    } catch (e) {
      console.error(`Can't apply ${this.constructor.name} to Shape ${shape}`);
      return shape;
    }
  }
}
