import {BrowserModule, HAMMER_LOADER} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {AuthModule} from '@auth/auth.module';
import {LayoutModule} from '@layout/layout.module';
import {AppRoutingModule} from './app-routing.module';
import {AmplifyAngularModule, AmplifyService} from 'aws-amplify-angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClientModule} from '@angular/common/http';
import {NotificationsModule} from '@app/notifications/notifications.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AuthModule,
    LayoutModule,
    AppRoutingModule,
    AmplifyAngularModule,
    HttpClientModule,
    NotificationsModule
  ],
  providers: [
    AmplifyService,
    {provide: HAMMER_LOADER, useValue: () => new Promise(() => {})}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
