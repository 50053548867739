import {Country} from '@shape/data/countries';

export class SignupDTO {
  firstName: string;
  lastName: string;
  country: Country;
  username: string;
  password: string;
  email: string;
  privacyPolicyAgreed: boolean;

  constructor(init?: Partial<SignupDTO>) {
    Object.assign(this, init);
  }
}
