import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'shp-player-volume',
  templateUrl: './volume.component.html',
  styleUrls: ['./volume.component.scss'],
  styles: [`
      shp-player-volume {
          display: flex;
      }
  `],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VolumeComponent {
  private static DEFAULT_VOLUME = 0.9;

  @Input() volume: number;
  @Input() muted: boolean;

  @Output() change: EventEmitter<number> = new EventEmitter();

  private volume$ = new BehaviorSubject<number>(VolumeComponent.DEFAULT_VOLUME);
  opened = false;

  constructor() {
    this.volume$.subscribe(volume => {
      this.change.emit(volume);
    });
  }

 toggleMute(): void {
    if (this.muted) {
      this.change.emit(this.volume$.getValue());
    } else {
      this.change.emit(0);
    }
    this.muted = !this.muted;
  }

  private setVolume(volume: number): void {
    this.muted = false;
    this.volume$.next(volume);
  }

}
