import {Injectable} from '@angular/core';
import {AppsyncService} from '@shape/appsync.service';
import {from, Observable, of} from 'rxjs';
import {Shape} from '@lib/models/shape';
import {catchError, filter, map, switchMap} from 'rxjs/operators';
import {ErrorsService} from '@shape/errors.service';
import {ShapeError} from '@shape/models/shape-error';
import {ShapeErrorType} from '@shape/models/shape-error-type';
import {EditShapeCommand} from '@editor/models/edit-shape-command';
import CreateEditShapeCommandInput from '@editor/graphql/mutations/create-edit-shape-command-input';
import CreateEditShapeCommandResponse from '@editor/graphql/mutations/create-edit-shape-command-response';
import {CreateEditShapeCommand} from '@editor/graphql/mutations/create-edit-shape-command';
import {v4 as uuid} from 'uuid';
import {EditSceneBody} from '@editor/models/edit-commands/edit-scene-body';
import {DeleteScene} from '@editor/models/edit-commands/delete-scene';
import {EditTitle} from '@editor/models/edit-commands/edit-title';
import {NewScene} from '@editor/models/edit-commands/new-scene';
import {EditTheme} from '@editor/models/edit-commands/edit-theme';
import {Storage} from 'aws-amplify';
import {EditSceneDuration} from '@editor/models/edit-commands/edit-scene-duration';
import {EditSceneOrder} from '@editor/models/edit-commands/edit-scene-order';
import {EditSceneBackground} from '@editor/models/edit-commands/edit-scene-background';

@Injectable({
  providedIn: 'root',
})
export class EditorApiService {
  constructor(
    private appsyncService: AppsyncService,
    private errorsService: ErrorsService
  ) {}

  private static COMMAND_TYPES = {
    NewScene,
    EditTitle,
    EditSceneBackground,
    EditSceneBody,
    DeleteScene,
    EditTheme,
    EditSceneDuration,
    EditSceneOrder
  };

  static buildCommand(type: any, payload: string, createdAt: string): EditShapeCommand {
    return new this.COMMAND_TYPES[type]({...JSON.parse(payload), createdAt});
  }

  private static commandFromResponse(data: any): EditShapeCommand {
    const response = <CreateEditShapeCommandResponse>data;
    return this.buildCommand(
      response.createEditShapeCommand.type,
      response.createEditShapeCommand.payload,
      response.createEditShapeCommand.createdAt
    );
  }

  createEditShapeCommand(command: EditShapeCommand, shape: Shape): Observable<EditShapeCommand> {
    const commandId = uuid();
    const input: CreateEditShapeCommandInput = {
      id: commandId,
      editShapeCommandShapeId: shape.id,
      type: command.constructor.name,
      payload: command.payload,
      createdAt: command.createdAt
    };
    const optimisticEditedShape: Shape = command.apply(shape);
    return from(this.appsyncService.client).pipe(
      switchMap(client => {
        return client.mutate<CreateEditShapeCommandResponse, {input: CreateEditShapeCommandInput}>({
            mutation: CreateEditShapeCommand,
          variables: {input: input},
            optimisticResponse: () => ({
              createEditShapeCommand: {
                __typename: 'EditShapeCommand',
                id: commandId,
                shape: {...optimisticEditedShape, __typename: 'Shape'},
                type: command.constructor.name,
                payload: command.payload,
                createdAt: command.createdAt
              }
            })
          });
        }
      ),
      map(({data}) => EditorApiService.commandFromResponse(data)),
      catchError(this.onShapeError.bind(this, ShapeErrorType.GRAPHQL)),
      filter(cmd => !!cmd)
    );
  }

  downloadTranscript(shapeId: string): Promise<string> {
    return new Promise<string>(resolve => {
      Storage.get(`transcripts/${shapeId}`, {download: true})
        .then((result: { Body }) => {
          const transcript: { paragraphs: string[] } = JSON.parse(result.Body.toString());
          resolve(transcript.paragraphs
            .map(paragraph => `<p>${paragraph}</p>`)
            .join('\n'));
        })
        .catch(({message}) => console.error(`Cannot load transcript file: ${message}`));
    });
  }

  private onShapeError(type: ShapeErrorType, output: any, error: { message: string }): Observable<any> {
    if (error.message) {
      const shapeError = new ShapeError({message: error.message, type: type});
      this.errorsService.updateLastError(shapeError);
    }
    return of(output);
  }
}
