import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot} from '@angular/router';
import {AuthApiService} from '@auth/auth-api.service';
import {Observable} from 'rxjs';
import {first, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate, CanActivateChild, CanLoad {
  constructor(private authApi: AuthApiService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authApi.authenticated$.pipe(
      tap(this.signinWhenNotLogged.bind(this))
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.authApi.authenticated$.pipe(
      tap(this.signinWhenNotLogged.bind(this))
    );
  }

  canLoad(route: Route): Observable<boolean> {
    return this.authApi.authenticated$.pipe(
      first(),
      tap(this.signinWhenNotLogged.bind(this))
    );
  }

  private signinWhenNotLogged(logged: boolean): void {
    if (!logged) {
      this.router.navigate(['signin']);
    }
  }
}
