import {NotificationStatus} from '@app/notifications/models/notification-status.enum';

export class Notification {
  id: string;
  recipient: string;
  body: any;
  status: NotificationStatus;
  updatedAt?: string;
  createdAt?: string;


  constructor(init?: Partial<Notification>) {
    Object.assign(this, init);
  }
}
