import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PlayerService} from '@lib/components/player/player.service';
import {Scene} from '@lib/models/scene';
import {Theme} from '@lib/models/theme';
import {PlayerOptions} from '@lib/models/player-options';

@Component({
  selector: 'shp-previewer',
  templateUrl: './previewer.component.html',
  styleUrls: ['./previewer.component.scss'],
  providers: [PlayerService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewerComponent {
  @Input() options: PlayerOptions = new PlayerOptions();
  @Input() scene: Scene;
  @Input() theme: Theme;
  @Input() scalable = false;

  constructor() { }

  getStyle() {
    if (this.options.scalable === false) {
      return {
        'width.px': this.options.width,
        'height.px': this.options.height
      };
    }
    return null;
  }
}
