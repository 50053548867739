export class Theme {
  id: string;
  label: string;
  preview: string;

  constructor(init?: Partial<Theme>) {
    Object.assign(this, init);
  }
}

export const themes = [
  new Theme({
    id: '1',
    label: 'Theme orange',
    preview: '/assets/theme/preview/1.png'
  }),
  new Theme({
    id: '2',
    label: 'Theme light',
    preview: '/assets/theme/preview/2.png'
  })
];
