import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {SigninComponent} from './containers/signin/signin.component';
import {CompleteComponent} from '@auth/containers/complete/complete.component';
import {GuestGuardService} from '@auth/guards/guest-guard.service';
import {SignupComponent} from '@auth/containers/signup/signup.component';
import {ConfirmComponent} from '@auth/containers/confirm/confirm.component';
import {PrivacyComponent} from '@auth/containers/privacy/privacy.component';
import {LayoutType} from '@layout/models/layout-type';

const routes: Routes = [
  {
    path: '',
    canActivate: [GuestGuardService],
    children: [
      {
        path: 'signup',
        component: SignupComponent,
        data: {layout: LayoutType.NAKED},
      },
      {
        path: 'signin',
        component: SigninComponent,
        data: {layout: LayoutType.NAKED},
      },
      {
        path: 'complete',
        component: CompleteComponent,
        data: {layout: LayoutType.NAKED},
      },
      {
        path: 'confirm',
        component: ConfirmComponent,
        data: {layout: LayoutType.NAKED},
      },
      {
        path: 'privacy',
        component: PrivacyComponent,
        data: {layout: LayoutType.NAKED},
      },
      { path: '', redirectTo: '/signin', pathMatch: 'full' },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
