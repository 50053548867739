import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {filter} from 'rxjs/operators';
import {Scene} from '@lib/models/scene';
import {PlayerOptions} from '@lib/models/player-options';

export type ControlCommandVerb = 'SEEK' | 'VOLUME' | 'FULLSCREEN' | 'GO_TO_NEXT' | 'GO_TO_PREV';
export type VideoCommandVerb = 'PLAY' | 'PAUSE' | 'RESUME' | 'STOP';

export interface ControlCommandScene {
  verb: ControlCommandVerb;
  data?: any;
}

export interface VideoCommandScene {
  verb: VideoCommandVerb;
  data?: any;
}

@Injectable()
export class PlayerService {
  private _playerOptions: PlayerOptions;
  private scenes$: BehaviorSubject<Scene[]> = new BehaviorSubject<Scene[]>([]);
  private controlCommand$ = new BehaviorSubject<ControlCommandScene>(null);
  private videoCommand$ = new BehaviorSubject<VideoCommandScene>({verb: 'STOP'});
  private currentScene$ = new BehaviorSubject<Scene>(null);
  private prevScene$ = new BehaviorSubject<Scene>(null);
  private nextScene$ = new BehaviorSubject<Scene>(null);
  private time$ = new BehaviorSubject<number>(0);
  private duration$ = new BehaviorSubject<number>(0);

  get playerOptions(): PlayerOptions {
    return this._playerOptions;
  }

  set playerOptions(options) {
    this._playerOptions = options;
  }

  /** Getter observable */
  watchControlCommand(): Observable<ControlCommandScene> {
    return this.controlCommand$.asObservable().pipe(filter(command => command !== null));
  }

  watchVideoCommand(): Observable<VideoCommandScene> {
    return this.videoCommand$.asObservable().pipe(filter(command => command !== null));
  }

  watchCurrentScene(): Observable<Scene> {
    return this.currentScene$.asObservable();
  }

  watchScenes(): Observable<Scene[]> {
    return this.scenes$.asObservable();
  }

  getCurrentScene(): Scene {
    return this.currentScene$.getValue();
  }

  watchPrevScene(): Observable<Scene> {
    return this.prevScene$.asObservable();
  }

  getPrevScene(): Scene {
    return this.prevScene$.getValue();
  }

  watchNextScene(): Observable<Scene> {
    return this.nextScene$.asObservable();
  }

  getNextScene(): Scene {
    return this.nextScene$.getValue();
  }

  watchTime(): Observable<number> {
    return this.time$.asObservable();
  }

  getTime(): number {
    return this.time$.getValue();
  }

  watchDuration(): Observable<number> {
    return this.duration$.asObservable();
  }

  getDuration(): number {
    return this.duration$.getValue();
  }

  /** VIDEO Commands */
  play(): void {
    this.videoCommand$.next({verb: 'PLAY'});
  }

  resume(): void {
    this.videoCommand$.next({verb: 'RESUME'});
  }

  pause(): void {
    this.videoCommand$.next({verb: 'PAUSE'});
  }

  stop(): void {
    this.videoCommand$.next({verb: 'STOP'});
  }

  /** CONTROLS commands */
  seek(time): void {
    this.controlCommand$.next({verb: 'SEEK', data: {time}});
  }

  goToPrev(): void {
    this.controlCommand$.next({verb: 'GO_TO_PREV'});
  }

  goToNext(): void {
    this.controlCommand$.next({verb: 'GO_TO_NEXT'});
  }

  /** VOLUME commands */
  volume(volume): void {
    this.controlCommand$.next({verb: 'VOLUME', data: {volume}});
  }

  fullscreen(): void {
    // TODO check if fullscreen or not
    const enabled = window.innerWidth === screen.width && window.innerHeight === screen.height;
    this.controlCommand$.next({verb: 'FULLSCREEN', data: {enabled}});
  }

  /** PLAYER SCENES */
  updateScenes(current = null, prev = null, next = null) {
    this.currentScene$.next(current);
    this.prevScene$.next(prev);
    this.nextScene$.next(next);
  }

  updateTime(time: number): void {
    this.time$.next(time);
  }

  updateDuration(duration: number): void {
    this.duration$.next(duration);
  }

  updateAllScenes(scenes: Scene[]) {
    this.scenes$.next(scenes);
  }
}
