import {Component, ChangeDetectionStrategy, Output, EventEmitter, Input} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ConfirmDTO} from '@auth/models/confirm-dto';

@Component({
  selector: 'shp-confirm-form',
  templateUrl: './confirm-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmFormComponent {
  @Input() message: string;
  @Output() submitted = new EventEmitter<ConfirmDTO>();

  confirmForm = this.fb.group({
    username: ['', {
      validators: Validators.required,
      updateOn: 'blur'
    }],
    code: ['', {
      validators: Validators.required,
      updateOn: 'blur'
    }]
  });

  get username() { return this.confirmForm.get('username'); }
  get code() { return this.confirmForm.get('code'); }

  constructor(private fb: FormBuilder) { }

  confirm() {
    if (this.confirmForm.valid) {
      const confirm = new ConfirmDTO(this.confirmForm.value);
      this.submitted.emit(confirm);
    }
  }
}
