import {Media} from '@lib/models/media';

export class Scene {
  id?: string;
  index?: number;
  time?: {
    start?: number,
    end?: number
  };
  media: Media;
  text: string;
  duration: number;
  paragraph: number;
  search_terms: string[];
  first?: boolean;
  last?: boolean;

  constructor(init?: Partial<Scene>) {
    Object.assign(this, init);
  }
}
