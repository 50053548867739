import {Shape} from '@lib/models/shape';
import {EditShapeCommand} from '@editor/models/edit-shape-command';
import {Theme} from '@lib/models/theme';

/**
 * When you create a new command, remember to add its type into Class "EditorApiService"
 * */
export class EditTheme extends EditShapeCommand {
  theme: Theme;

  constructor(init: Partial<EditTheme>) {
    super(init);
  }

  get payload(): string {
    /***/
    return JSON.stringify({
      theme: this.theme,
    });
  }

  apply(shape: Shape): Shape {
    try {
      const body = JSON.parse(shape.body);
      return new Shape({
        ...shape,
        body: JSON.stringify({
          ...body,
          theme: this.theme
        })
      });
    } catch (e) {
      console.error(`Can't apply ${this.constructor.name} to Shape ${shape}`);
      return shape;
    }
  }
}
