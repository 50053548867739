import {Component, Inject, OnInit} from '@angular/core';
import {ControlsComponent} from '@lib/components/player/controls/controls.component';
import {PlayerService} from '@lib/components/player/player.service';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'shp-player-mobile-controls',
  templateUrl: './mobile-controls.component.html',
  styleUrls: ['./mobile-controls.component.scss'],
})
export class MobileControlsComponent extends ControlsComponent implements OnInit {
  started = false;
  constructor(
    @Inject(DOCUMENT) public document: any,
    public player: PlayerService
  ) {
    super(player, document);
  }

  ngOnInit(): void {
    if (this.options.autoplay) {
      this.started = true;
    }
  }
}
