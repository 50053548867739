import {Component, EventEmitter, Input, NgZone, OnInit, Output} from '@angular/core';
import {ShapeError} from '@shape/models/shape-error';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {BehaviorSubject, Observable} from 'rxjs';
import {Shape} from '@lib/models/shape';
import {environment} from '@env/environment';
import {EditorService} from '@editor/editor.service';
import {ActivatedRoute} from '@angular/router';
import {MatDialog} from '@angular/material';
import {EditTitle} from '@editor/models/edit-commands/edit-title';
import {ShapeStatus} from '@lib/models/shape-status.enum';

@Component({
  selector: 'shp-console-layout',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss']
})
export class ConsoleLayoutComponent implements OnInit {
  @Input() error: ShapeError;
  shape$: Observable<Shape>;
  savingChanges$: Observable<boolean>;
  ShapeStatus = ShapeStatus;

  private pushingCommand$ = new BehaviorSubject<boolean>(false);

  initControls: any;
  editorFroala: any;
  prevShapeValue = '';
  wysiwygOpts: {[key: string]: any} = {
    key: environment.froalaKey,
    placeholderText: 'Type the title...',
    toolbarButtons: [],
    quickInsertTags: [],
    charCounterCount: true,
    fileUploadURL: false,
    imageUploadURL: false,
    htmlExecuteScripts: false,
    htmlUntouched: true,
    listAdvancedTypes: false,
    htmlRemoveTags: ['script', 'br'],
    toolbarInline: true,
    multiLine: false,
    enter: 'ENTER_BR',
    initOnClick: true,
    charCounterMax: 60,
    events: {
      'initialized': () => {
        return this.ngZone.run(() => {
          this.editorFroala = this.initControls.getEditor();
          this.prevShapeValue = this.editorFroala.html.get();
        });
      },
      'blur': () => {
        return this.ngZone.run(() => this.editTitleShape(this.editorFroala.html.get()));
      }
    },
  };

  constructor(
    private editor: EditorService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private ngZone: NgZone
  ) {
    this.shape$ = editor.editingShape$;
  }

  froalaInit(event): void {
    this.initControls = event;
    this.initControls.initialize();
  }

  async editTitleShape(title: string): Promise<void> {
    if (title !== this.prevShapeValue) {
      if (!title) {
        title = 'Untitled shape';
      }
      this.pushingCommand$.next(true);
      const command = new EditTitle({ title });
      await this.editor.pushEditCommand(command);
      this.prevShapeValue = title;
      this.pushingCommand$.next(false);
    }
  }

  ngOnInit() { }
}
