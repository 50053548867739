import { Pipe, PipeTransform } from '@angular/core';
/*
 * Takes an username and return the initials (max: 2 letters)
 * Usage:
 *   value | initialsUser
 * Example:
 *   {{ john.doe | initialsUser }} => JD
 *   {{ john.doe.5 | initialsUser }} => J5
 *   {{ johndoe | initialsUser }} => J
*/
@Pipe({name: 'initialsUser'})
export class InitialsUserPipe implements PipeTransform {
  transform(value: string): string {
    if (value) {
      const initials = value.match(/\b\w/g) || [];
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    }
  }
}
