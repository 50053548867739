import {CSSPlugin, TimelineMax} from 'gsap/all';
import {AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {SceneService} from '@lib/components/player/scene/scene.service';
import {Scene} from '@lib/models/scene';
import {MediaType} from '@lib/models/media-type';
const plugins = [ CSSPlugin];

@Component({
  template: `<ng-content></ng-content><ng-container #videoBackground></ng-container><ng-container #imageBackground></ng-container>`,
  selector: `shp-scene-theme`
})
export class SceneThemeComponent implements AfterViewInit {
  @Input() scene: Scene;
  @Input() mode: 'preview' | 'player' = 'player';
  @ViewChild('videoBackground', {static: false}) videoBackground: ElementRef;
  @ViewChild('imageBackground', {static: false}) imageBackground: ElementRef;
  mediaType = MediaType;
  timeline: TimelineMax;
  el = '';
  constructor(public sceneService: SceneService) {}

  ngAfterViewInit(): void {
    this.sceneService.video = this.videoBackground;
    this.sceneService.image = this.imageBackground;
  }

  generateTimeline(opts: any): void {
    this.timeline = new TimelineMax(opts);
    this.timeline.add('begin');
    this.enterScene();
    this.exitScene();
  }

  /** Animation */
  enterScene(): void {
    this.timeline.to(this.el, 0.5, {opacity: '1', zIndex: '1'}, 'begin-=0');
  }
  exitScene(): void {
    if (!this.scene.last) {
      this.timeline.to(this.el, 1, {opacity: 0, zIndex: -1}, `begin+=${this.scene.duration}`);
    } else {
      this.timeline.to(this.el, 0, {opacity: 1, zIndex: 1}, `begin+=${this.scene.duration}`);
    }
  }

  /** Styling */
  getStyle(): any {
    if (this.scene && this.scene.text) {
      const numWords = this.scene.text.split(' ').length;
      const fontSize = ((1 - (numWords / 200) > 0.5) ? 1 - (numWords / 200) : 0.5)  + 'em';
      const textAlign = 'left';
      const alignItems = 'flex-end';
      return {fontSize, textAlign, alignItems};
    }
    return null;
  }
}
