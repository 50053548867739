import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {PageNotFoundComponent} from '@layout/page-not-found/page-not-found.component';
import {AuthGuardService} from '@auth/guards/auth-guard.service';
import {LayoutType} from '@layout/models/layout-type';

const routes: Routes = [
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashboard.module#DashboardModule',
    data: {layout: LayoutType.DEFAULT},
    canLoad: [AuthGuardService]
  },
  {
    path: 'builder',
    loadChildren: './builder/builder.module#BuilderModule',
    canLoad: [AuthGuardService],
    outlet: 'slideup',
    data: {animation: 'slideUp'}
  },
  {
    path: 'preview',
    loadChildren: './preview/preview.module#PreviewModule',
    canLoad: [AuthGuardService],
    outlet: 'preview',
  },
  {
    path: 'editor',
    loadChildren: './editor/editor.module#EditorModule',
    canLoad: [AuthGuardService]
  },
  {
    path: 'editor',
    loadChildren: './editor/editor.module#EditorModule',
    canLoad: [AuthGuardService],
    outlet: 'popup'
  },
  {
    path: 'publisher',
    loadChildren: './publisher/publisher.module#PublisherModule',
    canLoad: [AuthGuardService],
    outlet: 'popup'
  },
  {
    path: 'media-library',
    loadChildren: './media-library/media-library.module#MediaLibraryModule',
    canLoad: [AuthGuardService],
    outlet: 'popup'
  },
  {path: '', redirectTo: '/dashboard', pathMatch: 'full'},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    enableTracing: false,
    preloadingStrategy: PreloadAllModules
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
