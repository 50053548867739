import gql from 'graphql-tag';

export const UpdateShapeStatus = gql`
  mutation UpdateShape($input: UpdateShapeInput!) {
    updateShape(input: $input) {
      __typename
      id
      status
      createdAt
      updatedAt
    }
  }`;
