import gql from 'graphql-tag';

const query = gql`
    query ListUserNotifications($filter: ModelUserNotificationFilterInput) {
        listUserNotifications(limit: 50, filter: $filter) {
            __typename
            items {
                __typename
                id
                recipient
                status
                body
                createdAt
                updatedAt
            }
            nextToken
        }
    }`;

export { query as ListUserNotifications };
