import {Injectable} from '@angular/core';
import {AppsyncService} from '@shape/appsync.service';
import {ErrorsService} from '@shape/errors.service';
import {BehaviorSubject, from, Observable, of} from 'rxjs';
import {Shape} from '@lib/models/shape';
import {catchError, filter, map, switchMap, tap} from 'rxjs/operators';
import GetShapeResponse from '@shape/graphql/queries/get-shape/get-shape-response';
import {GetShape} from '@shape/graphql/queries/get-shape/get-shape';
import {ApolloQueryResult, ObservableQuery} from 'apollo-client';
import {ShapeError} from '@shape/models/shape-error';
import {ShapeErrorType} from '@shape/models/shape-error-type';
import {MyShapesFilter} from '@shape/models/filters/my-shapes-filter';
import {NewScene} from '@editor/models/edit-commands/new-scene';
import {EditTitle} from '@editor/models/edit-commands/edit-title';
import {EditSceneBody} from '@editor/models/edit-commands/edit-scene-body';
import {DeleteScene} from '@editor/models/edit-commands/delete-scene';
import {EditTheme} from '@editor/models/edit-commands/edit-theme';
import {EditSceneDuration} from '@editor/models/edit-commands/edit-scene-duration';
import {EditSceneOrder} from '@editor/models/edit-commands/edit-scene-order';
import {EditShapeCommand} from '@editor/models/edit-shape-command';
import {EditSceneBackground} from '@editor/models/edit-commands/edit-scene-background';
import {ShapeStatus} from '@lib/models/shape-status.enum';
import ListMyShapesResponse from '@app/dashboard/graphql/query/list-my-shapes/list-my-shapes-response';
import {ListMyShapes} from '@app/dashboard/graphql/query/list-my-shapes/list-my-shapes';
import {UpdateShapeStatus} from '@app/dashboard/graphql/mutations/update-shape-status/update-shape-status';
import {DeleteShape} from '@app/dashboard/graphql/mutations/delete-shape/delete-shape';
import UpdateShapeStatusResponse from '@app/dashboard/graphql/mutations/update-shape-status/update-shape-status-response';
import DeleteShapeResponse from '@app/dashboard/graphql/mutations/delete-shape/delete-shape-response';

@Injectable({
  providedIn: 'root',
})
export class ShapeApiService {
  private static COMMAND_TYPES = {
    NewScene,
    EditTitle,
    EditSceneBackground,
    EditSceneBody,
    DeleteScene,
    EditTheme,
    EditSceneDuration,
    EditSceneOrder
  };
  myShapesLoading$ = new BehaviorSubject<boolean>(false);

  constructor(
    private appsyncService: AppsyncService,
    private errorsService: ErrorsService
  ) { }

  private myShapesSubscription;
  private filterApplied: MyShapesFilter = null;
  private shapeSubscription;
  private watchedListMyShapes: ObservableQuery<any>;

  static buildCommand(type: any, payload: string, createdAt: string): EditShapeCommand {
    return new this.COMMAND_TYPES[type]({...JSON.parse(payload), createdAt});
  }

  watchListMyShapes(listFilter: MyShapesFilter = null): Observable<Shape[]> {
    this.filterApplied = listFilter;
    this.myShapesLoading$.next(true);
    return from(this.appsyncService.client).pipe(
      switchMap(client => {
        // @ts-ignore
        this.watchedListMyShapes = client.watchQuery<ListMyShapesResponse>({
          query: ListMyShapes,
          fetchPolicy: 'cache-and-network',
          variables: { filter: listFilter }
        });
        return this.fromMyShapesObservableQuery(this.watchedListMyShapes);
      }),
      filter(({loading}) => !loading),
      map(({data}) => data.listShapes.items.map(itemShape => {
        return itemShape.editCommands && itemShape.editCommands.items
          ? this.applyCommands(new Shape({ ...itemShape }), itemShape.editCommands.items)
          : new Shape({ ...itemShape });
      })),
      tap(() => this.myShapesLoading$.next(false)),
      catchError(this.onShapeError.bind(this, ShapeErrorType.GRAPHQL, []))
    );
  }

  async refetchListMyShapes(listFilter: MyShapesFilter = null) {
    if (this.watchedListMyShapes) {
      this.myShapesLoading$.next(true);
      this.filterApplied = listFilter;
      return await this.watchedListMyShapes.refetch({ filter: listFilter });
    }
  }

  async archiveShape(shapeId: string) {
    this.myShapesLoading$.next(true);
    this.updateShapeStatus(shapeId, ShapeStatus.ARCHIVED);
  }

  async restoreShape(shapeId: string) {
    this.myShapesLoading$.next(true);
    this.updateShapeStatus(shapeId, ShapeStatus.DRAFT);
  }

  private async updateShapeStatus(shapeId, status: ShapeStatus) {
    const input = {
      id: shapeId,
      status: status
    };
    await (await this.appsyncService.client).mutate<UpdateShapeStatusResponse, { input: { id: string; status: ShapeStatus; } }>({
      mutation: UpdateShapeStatus,
      variables: {input: input},
      optimisticResponse: () => ({updateShape: { __typename: 'OptimisticShape', ...input }}),
      update: (proxy, {data: {updateShape: shape}}) => this.onUpdateShape(proxy, shape)
    });
  }

  async deleteShape(shapeId: string) {
    this.myShapesLoading$.next(true);
    const input = {
      id: shapeId
    };
    await (await this.appsyncService.client).mutate<DeleteShapeResponse, { input: { id: string; } }>({
      mutation: DeleteShape,
      variables: {input: input},
      optimisticResponse: () => ({deleteShape: { __typename: 'OptimisticShape', ...input }}),
      update: (proxy, {data: {deleteShape: shape}}) => this.onDeleteShape(proxy, shape)
    });
  }

  async onDeleteShape(proxy, shape) {
    if (shape && shape.id && shape.__typename !== 'OptimisticShape') {
      const cachedResponse: ListMyShapesResponse = proxy.readQuery({query: ListMyShapes, variables: { filter: this.filterApplied }});
      const items = cachedResponse.listShapes.items.filter(item => item.id !== shape.id);
      const listMyShapes = {
        listShapes: {
          ...cachedResponse.listShapes,
          items: items
        }
      };
      proxy.writeQuery({query: ListMyShapes, data: listMyShapes, variables: { filter: this.filterApplied }});
    }
  }

  async onUpdateShape(proxy, shape) {
    if (shape && shape.id && shape.__typename !== 'OptimisticShape') {
      const cachedResponse: ListMyShapesResponse = proxy.readQuery({query: ListMyShapes, variables: { filter: this.filterApplied }});
      const items = cachedResponse.listShapes.items.filter(item => item.id !== shape.id);
      const listMyShapes = {
        listShapes: {
          ...cachedResponse.listShapes,
          items: items
        }
      };
      proxy.writeQuery({query: ListMyShapes, data: listMyShapes, variables: { filter: this.filterApplied }});
    }
  }

  unwatchListMyShapes(): void {
    if (this.myShapesSubscription) {
      this.myShapesSubscription.unsubscribe();
    }
  }

  watchShape(shapeId: string): Observable<Shape> {
    return from(this.appsyncService.client).pipe(
      switchMap(client => this.fromShapeObservableQuery(
        client.watchQuery<GetShapeResponse>({
          query: GetShape,
          fetchPolicy: 'cache-and-network',
          variables: { id: shapeId }
        })
      )),
      filter(({loading}) => !loading),
      map(({data}) => {
        return this.applyCommands(new Shape({ ...data.getShape }), data.getShape.editCommands.items);
      }),
      catchError(this.onShapeError.bind(this, ShapeErrorType.GRAPHQL, null))
    );
  }

  unwatchShape(): void {
    if (this.shapeSubscription) {
      this.shapeSubscription.unsubscribe();
    }
  }

  getShape(shapeId: string): Observable<Shape> {
    return from(this.appsyncService.client).pipe(
      switchMap(client => client.query<GetShapeResponse>({
        query: GetShape,
        fetchPolicy: 'network-only',
        variables: { id: shapeId }
      })),
      map(({data}) => {
        return this.applyCommands(new Shape({ ...data.getShape }), data.getShape.editCommands.items);
      }),
      catchError(this.onShapeError.bind(this, ShapeErrorType.GRAPHQL, []))
    );
  }

  private fromMyShapesObservableQuery(observableQuery): Observable<ApolloQueryResult<ListMyShapesResponse>> {
    return new Observable<ApolloQueryResult<ListMyShapesResponse>>(observer => {
      this.myShapesSubscription = observableQuery.subscribe(
        result => observer.next(result),
        error => observer.error(error)
      );
      return this.myShapesSubscription;
    });
  }

  private fromShapeObservableQuery(observableQuery): Observable<ApolloQueryResult<GetShapeResponse>> {
    return new Observable<ApolloQueryResult<GetShapeResponse>>(observer => {
      this.shapeSubscription = observableQuery.subscribe(
        result => {
          !result.data || result.data.getShape
            ? observer.next(result)
            : observer.error(new ShapeError({
              message: 'Shape not found',
              type: ShapeErrorType.SHAPE_NOTFOUND
            }));
        },
        error => observer.error(error)
      );
      return this.shapeSubscription;
    });
  }

  private applyCommands(shape: Shape, commandItems?: { type: string, payload: string, createdAt: string }[]): Shape {
    if (!commandItems || !commandItems.length) {
      return shape;
    }
    const editCommands = commandItems.map(item => {
      return ShapeApiService.buildCommand(item.type, item.payload, item.createdAt);
    });
    return editCommands.reduce((updatedShape, command) => {
      return command.apply(updatedShape);
    }, shape);
  }

  private onShapeError(type: ShapeErrorType, output: any, error: {message: string}): Observable<any> {
    const shapeError = new ShapeError({ message: error.message, type: type });
    this.errorsService.updateLastError(shapeError);
    return of(output);
  }
}
