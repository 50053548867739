import PubSub from '@aws-amplify/pubsub';
import API from '@aws-amplify/api';
import Amplify from 'aws-amplify';
import awsConfig from './aws-exports';
import 'hammerjs';

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {AppModule} from '@app/app.module';
import {environment} from '@env/environment';

Amplify.configure(awsConfig);
PubSub.configure(awsConfig);
API.configure(awsConfig);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
