import {NgModule} from '@angular/core';
import { NotificationsListComponent } from './components/notifications-list/notifications-list.component';
import {ShapeModule} from '@shape/shape.module';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [NotificationsListComponent],
    imports: [
        CommonModule,
        ShapeModule,
        RouterModule
    ],
  providers: [],
  exports: [
    NotificationsListComponent
  ]
})
export class NotificationsModule { }
