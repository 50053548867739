import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Notification} from '@app/notifications/models/notification';
import {NotificationStatus} from '@app/notifications/models/notification-status.enum';
import {NotificationApiService} from '@app/notifications/notification-api.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'shp-notifications-list',
  templateUrl: './notifications-list.component.html',
  styleUrls: ['./notifications-list.component.scss'],
  styles: [`
    .shp-notification-list {
      display: flex;
    }
  `],
  host: {
    'class': 'shp-notification-list'
  },
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateY(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class NotificationsListComponent implements OnChanges {
  @Input() notifications: Notification[] = [];
  NotificationStatus = NotificationStatus;
  constructor(private notificationApi: NotificationApiService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.notifications && changes.notifications.currentValue) {
      this.notifications = this.notifications.map((item) => new Notification({...item, body: JSON.parse(item.body)}));
    }
  }

  async toggleReadUnRead(notification) {
    const updateNotification = notification;
    updateNotification.status = (notification.status === NotificationStatus.READ) ? NotificationStatus.UNREAD : NotificationStatus.READ;
    await this.notificationApi.updateUserNotification(updateNotification);
  }

  async markAsRead(notification) {
    if (notification.status === NotificationStatus.UNREAD) {
      await this.notificationApi.updateUserNotification({...notification, status: NotificationStatus.READ});
    }
  }

  async delete(notification) {
    await this.notificationApi.deleteUserNotification(notification);
  }

  trackByFn(index, item) {
    return index; // or item.id
  }
}
