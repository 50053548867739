import {AfterViewInit, Directive, ElementRef, Input, OnInit} from '@angular/core';

@Directive({
  selector: '[shp-required-marker]'
})
export class RequiredMarkerDirective implements AfterViewInit {
  @Input('shp-required-marker') text: string = null;

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    const label = this.el.nativeElement.querySelector('.mat-form-field-required-marker');
    if (label) {
      label.innerText = ` ${this.text}`;
    }
  }

}
