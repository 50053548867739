import gql from 'graphql-tag';

const mutation = gql`
    mutation UpdateUserNotification($input: UpdateUserNotificationInput!) {
        updateUserNotification(input: $input) {
            __typename
            id
            recipient
            status
            createdAt
            updatedAt
        }
    }`;

export {mutation as UpdateUserNotification};
