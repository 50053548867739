import {
  AfterViewInit, ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import {TimelineMax} from 'gsap/all';
import {SceneService} from '@lib/components/player/scene/scene.service';
import {SceneThemeComponent} from '@lib/components/player/scene/themes/theme.class';
import {Scene} from '@lib/models/scene';
import {Theme, themes} from '@lib/models/theme';

@Component({
  selector: 'shp-shape-scene',
  templateUrl: './scene.component.html',
  styleUrls: ['./scene.component.scss'],
  providers: [SceneService],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SceneComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() scene: Scene;
  @Input() mode: 'preview' | 'player' = 'player';
  @Input() masterTimeline: TimelineMax = null;
  @Input() theme: Theme;
  @Output() sceneReady$ = new EventEmitter<any>();

  @ViewChild('themeComponent', {static: false}) sceneThemeComponent: SceneThemeComponent;
  text: string = null;
  body: { [key: string]: any } = {}; // it's an any type because this value is a string parsed in JSON

  private timelineCreated = false;

  constructor(
    private sceneService: SceneService
  ) {
    sceneService.sceneReady$.subscribe((scene: Scene) => {
      this.sceneReady$.next(scene);
    });
  }

  /** ===================================================== */

  /** ================== LIFECYCLE METHODS ================ */
  /** ===================================================== */
  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit(): void {
    this.sceneService.theme = this.sceneThemeComponent;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.scene) {
      if (!changes.scene.isFirstChange()) {
        this.init();
      }
    }
  }

  private init(): void {
    this.timelineCreated = false;
    this.sceneService.scene = this.scene;
    this.sceneService.parentTimeline = this.masterTimeline;
    this.sceneService.id = this.scene.id;
    this.sceneService.mode = this.mode;
  }
}
