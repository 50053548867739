export enum TrackCommands {
  SEEK = 'SEEK',
  NEXT = 'NEXT',
  PREV = 'PREV',
  PLAY = 'PLAY',
  PAUSE = 'PAUSE',
  START = 'START',
  COMPLETE = 'COMPLETE',
  UPDATE = 'UPDATE'
}

export namespace TrackCommands {
  export function getVerb(commands: TrackCommands): string {
    switch (commands) {
      case TrackCommands.SEEK:
        return 'https://w3id.org/xapi/video/verbs/seeked';
      case TrackCommands.NEXT:
        return 'https://w3id.org/xapi/video/verbs/seeked';
      case TrackCommands.PREV:
        return 'https://w3id.org/xapi/video/verbs/seeked';
      case TrackCommands.PLAY:
        return 'https://w3id.org/xapi/video/verbs/played';
      case TrackCommands.PAUSE:
        return 'https://w3id.org/xapi/video/verbs/paused';
      case TrackCommands.START:
        return 'http://adlnet.gov/expapi/verbs/initialized';
      case TrackCommands.COMPLETE:
        return 'http://adlnet.gov/expapi/verbs/completed';
      case TrackCommands.UPDATE:
        return 'http://adlnet.gov/expapi/verbs/progressed';
      default:
        return null;
    }
  }

  export function isStatement(command: TrackCommands | [TrackCommands, string | number]): boolean {
    switch (command instanceof Array ? command[0] : command) {
      case TrackCommands.SEEK:
      case TrackCommands.NEXT:
      case TrackCommands.PREV:
      case TrackCommands.PLAY:
      case TrackCommands.PAUSE:
      case TrackCommands.START:
      case TrackCommands.COMPLETE:
        return true;
      case TrackCommands.UPDATE:
        return false;
      default:
        return false;
    }
  }

  export function isState(command: TrackCommands | [TrackCommands, string | number]): boolean {
    switch (command instanceof Array ? command[0] : command) {
      case TrackCommands.SEEK:
      case TrackCommands.NEXT:
      case TrackCommands.PREV:
      case TrackCommands.PLAY:
      case TrackCommands.PAUSE:
        return false;
      case TrackCommands.START:
      case TrackCommands.COMPLETE:
      case TrackCommands.UPDATE:
        return true;
      default:
        return false;
    }
  }

  export function getCommand(command: TrackCommands | [TrackCommands, string | number]) {
    return command instanceof Array ? command[0] : command;
  }

}
