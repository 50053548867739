import {ChangeDetectionStrategy, Component} from '@angular/core';
import {AuthApiService} from '@auth/auth-api.service';
import {SigninDTO} from '@auth/models/signin-dto';
import {BehaviorSubject, Subject} from 'rxjs';

@Component({
  selector: 'shp-signin',
  template: `
      <shp-signin-form
              (submitted)="onSubmitted($event)"
              [message]="errorMessage | async"
              [submitting]="submitting">
      </shp-signin-form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninComponent {
  errorMessage = new Subject<string>();
  submitting = false;

  constructor(private authApi: AuthApiService) {
  }

  onSubmitted(data: SigninDTO) {
    this.submitting = true;
    this.errorMessage.next();
    this.authApi.signIn(data)
      .catch(reason => {
        this.submitting = false;
        this.errorMessage.next(reason);
      });
  }
}
