import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {SigninDTO} from '@auth/models/signin-dto';

@Component({
  selector: 'shp-signin-form',
  templateUrl: './signin-form.component.html',
  styleUrls: ['./signin-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninFormComponent {
  @Input() message: string;
  @Input() submitting: boolean;
  @Output() submitted = new EventEmitter<SigninDTO>();

  showPassword = false;

  loginForm = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required]
  }, {updateOn: 'submit'});

  get username() {
    return this.loginForm.get('username');
  }

  get password() {
    return this.loginForm.get('password');
  }

  constructor(private fb: FormBuilder) {}

  signIn() {
    if (this.loginForm.valid) {
      const signIn = new SigninDTO(this.loginForm.value);
      this.submitted.emit(signIn);
    } else {
      this.message = 'Please, fill in the fields in the login form';
    }
  }
}
