import {animate, animateChild, query, style, transition, trigger} from '@angular/animations';

export const slideUpAnimation =
  trigger('slideUpAnimation', [
    transition('* => slideUp', [
      query(':enter, :leave', [
        style({ position: 'fixed', left: 0, right: 0, zIndex: 9000 })
      ]),
      query(':enter', [
        style({ top: '100%' })
      ], { optional: true }),
      query(':enter', [
        animate('300ms ease-out', style({ top: '0%'}))
      ], { optional: true }),
      query(':enter', animateChild(), { optional: true })
    ]),
    transition('slideUp => *', [
      query(':enter, :leave', [
        style({ position: 'fixed', left: 0, right: 0, zIndex: 9000 })
      ]),
      query('router-outlet ~ *', [
        style({}),
        animate(1, style({}))
      ], { optional: true }),
      query(':leave', [
        style({ top: '0%' })
      ]),
      query(':leave', [
        animate('300ms ease-in', style({ top: '100%' }))
      ]),
      query(':leave', animateChild())
    ])
  ]);
