import gql from 'graphql-tag';

const mutation = gql`
mutation UpdateLayout($type: LayoutType!) {
  updateLayout(type: $type) @client {
    type
  }
}`;

export { mutation as UpdateLayout };
