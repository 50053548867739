import {Shape} from '@lib/models/shape';
import {EditShapeCommand} from '@editor/models/edit-shape-command';

export abstract class EditShapeSceneCommand extends EditShapeCommand {
  createdAt: string;
  sceneId: string;

  protected constructor(init?: Partial<EditShapeSceneCommand>) {
    super(init);
  }

  abstract get payload(): string;

  abstract apply(shape: Partial<Shape>): Shape;
}
