import {Component, ChangeDetectionStrategy} from '@angular/core';
import {AuthApiService} from '@auth/auth-api.service';
import {Subject} from 'rxjs';
import {SignupDTO} from '@auth/models/signup-dto';
import {Country, countryList} from '@shape/data/countries';

@Component({
  selector: 'shp-signup',
  template: `
    <shp-signup-form
      (submitted)="onSubmitted($event)"
      [message]="errorMessage | async"
      [countries]="countries"
      [submitting]="submitting">
    </shp-signup-form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignupComponent {
  errorMessage = new Subject<string>();
  countries: Country[] = countryList;
  submitting = false;

  constructor(private authApi: AuthApiService) { }

  onSubmitted(data: SignupDTO) {
    this.submitting = true;
    this.errorMessage.next();
    this.authApi.signUp(data)
      .catch(reason => {
        this.submitting = false;
        this.errorMessage.next(reason);
      });
  }
}
