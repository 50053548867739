import gql from 'graphql-tag';

const query = gql`
query GetLastError {
  getLastError @client {
    message
    type
  }
}`;

export { query as GetLastError };
