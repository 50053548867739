import {Component, ChangeDetectionStrategy} from '@angular/core';
import {AuthApiService} from '@auth/auth-api.service';
import {Subject} from 'rxjs';
import {CompleteDTO} from '@auth/models/complete-dto';

@Component({
  selector: 'shp-complete',
  template: `
    <shp-complete-form
      (submitted)="onSubmitted($event)"
      [message]="errorMessage | async">
    </shp-complete-form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteComponent {
  errorMessage = new Subject<string>();

  constructor(private authApi: AuthApiService) { }

  onSubmitted(data: CompleteDTO) {
    this.authApi.complete(data)
      .catch(reason => this.errorMessage.next(reason));
  }
}
