import {Shape} from '@lib/models/shape';
import {EditShapeSceneCommand} from '@editor/models/edit-shape-scene-command';
import {Scene} from '@lib/models/scene';

/**
 * When you create a new command, remember to add its type into Class "EditorApiService"
 * */
export class EditSceneOrder extends EditShapeSceneCommand {
  from: number;
  to: number;

  constructor(init: Partial<EditSceneOrder>) {
    super(init);
  }

  get payload(): string {
    return JSON.stringify({
      sceneId: this.sceneId,
      from: this.from,
      to: this.to
    });
  }

  apply(shape: Shape): Shape {
    try {
      const body = JSON.parse(shape.body);
      const scenes = this.moveScene(body.scenes, this.from, this.to);
      return new Shape({
        ...shape,
        body: JSON.stringify({
          ...body,
          scenes
        })
      });
    } catch (e) {
      console.error(`Can't apply ${this.constructor.name} to Shape ${shape}`);
      return shape;
    }
  }

  moveScene(scenes, from, to): Scene[] {
    const fromElement = scenes.splice(from, 1) [0]; // cut the element at index 'from'
    scenes.splice(to, 0, fromElement); // insert it at index 'to'
    return scenes;
  }
}
