import {ShapeStatus} from './shape-status.enum';
import {S3Object} from '@lib/models/s3-object';

export class Shape {
  id: string;
  owner: string;
  version: number;
  title: string;
  status: ShapeStatus;
  statusInfo?: string;
  cover?: S3Object;
  body?: string;
  transcriptFile?: S3Object;
  lastPublishedUrl?: string;
  updatedAt?: string;
  createdAt?: string;
  theme?: string;

  constructor(init?: Partial<Shape>) {
    Object.assign(this, init);
  }
}
