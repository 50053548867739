import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {BehaviorSubject, fromEvent, Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {TimelineMax} from 'gsap/all';
import {DOCUMENT} from '@angular/common';
import {PlayerService} from '@lib/components/player/player.service';
import {Shape} from '@lib/models/shape';
import {Scene} from '@lib/models/scene';
import {Theme} from '@lib/models/theme';
import {PlayerOptions} from '@lib/models/player-options';
import {OutputPlayerSize} from '@lib/directives/scale-content.directive';
import {TrackCommands} from '@lib/models/track-commands.enum';

@Component({
  selector: 'shp-shape-player',
  templateUrl: 'player.component.html',
  styleUrls: ['./player.component.scss'],
  providers: [PlayerService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PlayerComponent implements OnDestroy, OnInit, OnChanges, AfterViewInit {
  @Input() options: PlayerOptions = new PlayerOptions();
  @Input() startSeconds;
  @Input() shape: Shape;
  @Input() static = false;
  audioSoundtrack: HTMLAudioElement;

  @ViewChild('previewContainer', {static: false}) previewContainer: ElementRef;

  @Output() command = new EventEmitter<TrackCommands | [TrackCommands, string | number]>();

  body: { [key: string]: any } = {}; // it's an any type because this value is a string parsed in JSON
  soundtrack: string;
  scenes: Scene[];

  masterTimeline;
  videoIsReadyToStart = false;
  assetsToBeLoad = 0;

  size: OutputPlayerSize;
  time$: Observable<number> | BehaviorSubject<number>;
  theme: Theme;
  fullscreenActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  masterTimelineReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private unsubscribe$ = new Subject<void>();

  constructor(
    @Inject(DOCUMENT) private document: any,
    private playerService: PlayerService,
  ) {
    // each controls have a Subject|Behaviour, In order to share that info with the single scene
    this.playerService.watchControlCommand().subscribe((command) => {
      switch (command.verb) {
        case 'FULLSCREEN':
          return this.fullscreenActive$.next(command.data.enabled);
        case 'VOLUME':
          return this.changeVolume(command.data.volume);
        case 'SEEK':
          this.command.emit([TrackCommands.SEEK, command.data.time / 1000]);
          return this.seek(command.data.time);
        case 'GO_TO_PREV':
          this.command.emit([TrackCommands.PREV, this.playerService.getPrevScene().index]);
          return this.goToPrevScene();
        case 'GO_TO_NEXT':
          this.command.emit([TrackCommands.NEXT, this.playerService.getNextScene().index]);
          return this.goToNextScene();
      }
    });
    this.playerService.watchVideoCommand().subscribe((command) => {
      switch (command.verb) {
        case 'PLAY':
          this.command.emit(TrackCommands.PLAY);
          return this.play();
        case 'PAUSE':
          this.command.emit(TrackCommands.PAUSE);
          return this.pause();
      }
    });
    // fullscreen event oservable, it utils to detect the fullscreen proprerty changed
    fromEvent(this.document, 'fullscreenchange').pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => this.playerService.fullscreen());
    // this BehaviourSubject utils to sink the timeline with our seekbar
    this.time$ = this.playerService.watchTime().pipe(takeUntil(this.unsubscribe$));
    this.time$.subscribe((time) => this.changeTime(time));

    this.masterTimeline = new TimelineMax({
      paused: true,
      onStart: () => this.onStartTimeline(),
      onUpdate: () => this.onUpdateTimeline(),
      onComplete: () => this.onCompleteTimeline()
    });
    this.masterTimeline.add('masterTimelineBegin');
  }

  /**
   ==============================================================
   ================ LIFECYCLE COMPONENT METHODS =================
   ==============================================================
   */
  ngOnInit(): void {
    this.playerService.playerOptions = this.options;
    this.body = JSON.parse(this.shape.body);
    this.theme = this.body.theme;
    this.createTimeline();
  }

  ngAfterViewInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.startSeconds && changes.startSeconds.currentValue !== changes.startSeconds.previousValue) {
      this.seek(changes.startSeconds.currentValue * 1000);
    }
  }

  ngOnDestroy(): void {
    this.pause();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private createTimeline(): void {
    this.prepareData();
    this.masterTimelineReady$.next(true);
  }

  private prepareData(): void {
    this.scenes = this.prepareScenes();
    this.playerService.updateAllScenes(this.scenes);
    this.soundtrack = (this.body && this.body.soundtrack) ? this.body.soundtrack : null;
    this.assetsToBeLoad = this.scenes.length + ((this.soundtrack) ? 1 : 0);
  }

  private prepareScenes(): Scene[] {
    let shapeScenes = (this.body && this.body.scenes) ? [...this.body.scenes] : [];
    let timeToStart = 0;
    shapeScenes = shapeScenes.map((scene: Scene, index: number): Scene => {
      scene = new Scene({
        ...scene,
        index,
        duration: (scene.duration < 5) ? 5 : scene.duration,
        first: index === 0,
        last: index === shapeScenes.length - 1,
        time: {
          start: timeToStart,
          end: timeToStart + scene.duration * 1000
        }
      });
      timeToStart = scene.time.end;
      return scene;
    });
    this.playerService.updateDuration(shapeScenes[shapeScenes.length - 1].time.end);
    return shapeScenes;
  }

  onAssetsLoaded(val, scene = null, index: number = null): void {
    if (!this.videoIsReadyToStart) {
      this.videoIsReadyToStart = true;
      if (this.soundtrack) {
        this.audioSoundtrack = new Audio(this.soundtrack);
        this.audioSoundtrack.autoplay = this.options.autoplay;
        this.audioSoundtrack.loop = true;
        this.audioSoundtrack.muted = true;
        this.audioSoundtrack.onloadeddata = this.start.bind(this);
      } else {
        this.start();
      }
    }
  }

  changeTime(time): void {
    // check the scena is the same that is rendered
    const currentScene = this.playerService.getCurrentScene();
    if (this.scenes && !(
      currentScene &&
      currentScene.time.start <= time &&
      time < currentScene.time.end
    )) {
      // scena is different, looking for new current scene based on the time
      this.scenes.forEach((scene: Scene, index: number) => {
        if (scene.time.start <= time && time < scene.time.end) {
          this.playerService.updateScenes(scene, this.scenes[index - 1], this.scenes[index + 1]);
        } else if (time === this.playerService.getDuration() && time === scene.time.end) {
          this.playerService.updateScenes(null, this.scenes[index], null);
        }
      });
      this.command.emit([TrackCommands.UPDATE, this.masterTimeline.time()]);
    }
  }

  /**
   ==============================================================
   ====================== TIMELINE METHODS ======================
   ==============================================================
   */

  private onStartTimeline(): void {
  }

  private onUpdateTimeline(): void {
    const intTime = parseInt(`${this.masterTimeline.time() * 10}`, 10);
    const intPrevTime = parseInt(`${this.playerService.getTime() / 1000 * 10}`, 10);
    if (intTime !== intPrevTime) {
      this.playerService.updateTime(this.masterTimeline.time() * 1000);
    }
  }

  private onCompleteTimeline(): void {
    this.command.emit(TrackCommands.COMPLETE);
    this.stop();
  }

  /**
   * Start
   * @Input timeToStart: number that represent the time has been started the video, it's in ms
   * */
  initPreview() {
    this.playerService.volume(0.9);
    this.playerService.updateScenes(this.scenes[0], null, this.scenes[1]);
    this.masterTimeline.pause();
  }

  async start(): Promise<void> {
    this.command.emit(TrackCommands.START);
    this.audioSoundtrack.pause();
    this.initPreview();
    if (this.options.autoplay) {
      this.playerService.play();

    }
  }

  /**
   ==============================================================
   ====================== CONTROLS METHODS ======================
   ==============================================================
   */
  async play(): Promise<void> {
    if (this.soundtrack) {
      this.audioSoundtrack.currentTime = this.masterTimeline.time() % this.audioSoundtrack.duration;
      try {
        this.audioSoundtrack.muted = false;
        await this.audioSoundtrack.play();
      } catch (e) {
        console.log(e);
      }
    }
    if (this.masterTimeline.progress() !== 1) {
      await this.masterTimeline.play();
    } else {
      this.masterTimeline.restart();
    }
  }

  pause() {
    if (this.audioSoundtrack) {
      this.audioSoundtrack.pause();
    }
    this.masterTimeline.pause();
  }

  stop() {
    if (this.audioSoundtrack) {
      this.audioSoundtrack.currentTime = 0;
    }
    this.pause();
  }

  resume() {
    this.play();
  }

  /**
   * @method change
   * This method is called when the user changes the value in the seekbar
   * @Input value - time in ms (milliseconds)
   **/
  private seek(time) {
    const videoPlayed = !this.masterTimeline.paused();
    const valueInSeconds = time / 1000;
    if (videoPlayed) {
      this.pause();
    }
    if (this.audioSoundtrack) {
      this.audioSoundtrack.currentTime = valueInSeconds % this.audioSoundtrack.duration;
    }
    this.masterTimeline.seek(valueInSeconds);
    this.playerService.updateTime(time);
    if (videoPlayed) {
      this.play();
    }
  }

  /**
   * @method goToPrevScene
   * This method is called when the user clicks on the button in controls bar, in order to pass at the PREV scene
   **/
  private goToPrevScene(): void {
    if (this.playerService.getPrevScene()) {
      const timeToStart = this.playerService.getPrevScene().time.start + 600;
      this.seek(timeToStart);
    }
  }

  /**
   * @method goToNextScene
   * This method is called when the user clicks on the button in controls bar, in order to pass at the NEXT scene
   **/
  private goToNextScene(): void {
    if (this.playerService.getNextScene()) {
      const timeToStart = this.playerService.getNextScene().time.start + 600;
      this.seek(timeToStart);
    }
  }

  /** VOLUME METHODS **/
  private changeVolume(volume): void {
    if (this.audioSoundtrack) {
      this.audioSoundtrack.volume = volume;
    }
  }

  getStyle() {
    if (this.options.scalable === false) {
      return {
        'width.px': this.options.width,
        'height.px': this.options.height
      };
    }
    return null;
  }
}
