import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SigninComponent} from './containers/signin/signin.component';
import {CompleteComponent} from './containers/complete/complete.component';
import {SigninFormComponent} from './components/signin-form/signin-form.component';
import {CompleteFormComponent} from './components/complete-form/complete-form.component';
import {AuthRoutingModule} from './auth-routing.module';
import {ReactiveFormsModule} from '@angular/forms';
import {ShapeModule} from '@shape/shape.module';
import {SignupFormComponent} from '@auth/components/signup-form/signup-form.component';
import {SignupComponent} from '@auth/containers/signup/signup.component';
import {ConfirmFormComponent} from '@auth/components/confirm-form/confirm-form.component';
import {ConfirmComponent} from '@auth/containers/confirm/confirm.component';
import {PrivacyComponent} from '@auth/containers/privacy/privacy.component';
import {PrivacyPolicyComponent} from '@auth/components/privacy-policy/privacy-policy.component';

@NgModule({
  declarations: [
    SigninComponent,
    CompleteComponent,
    SigninFormComponent,
    CompleteFormComponent,
    SignupComponent,
    SignupFormComponent,
    ConfirmComponent,
    PrivacyComponent,
    ConfirmFormComponent,
    CompleteFormComponent,
    PrivacyPolicyComponent
  ],
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    ShapeModule,
  ]
})
export class AuthModule { }
