import {Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation} from '@angular/core';
import {environment} from '@env/environment';
import FroalaEditor from 'froala-editor';

@Component({
  selector: 'shp-editable-text',
  templateUrl: './editable-text.component.html',
  styleUrls: ['./editable-text.component.scss'],
})
export class EditableTextComponent implements OnInit {
  @Input() text: string;
  @Output() changeEditableMode: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() changeValue: EventEmitter<string> = new EventEmitter<string>();

  initControls: any;
  editor: any;
  editorOptions: {[key: string]: any} = {
    key: environment.froalaKey,
    // toolbarButtons: ['bold', 'undo', 'redo'],
    toolbarButtons: {
      // Key represents the more button from the toolbar.
      moreText: {
        // List of buttons used in the  group.
        buttons: ['bold'],

        // Alignment of the group in the toolbar.
        align: 'left',

        // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
        buttonsVisible: 1
      },

      moreMisc: {
        buttons: ['saveText', 'cancelText'],
        align: 'right',
        buttonsVisible: 2
      }
    },
    quickInsertTags: [],
    charCounterCount: false,
    fileUploadURL: false,
    imageUploadURL: false,
    htmlExecuteScripts: false,
    htmlUntouched: true,
    listAdvancedTypes: false,
    htmlRemoveTags: ['script'],
    placeholderText: 'Type the text...',
    toolbarBottom: true,
    multiLine: false,
    enter: 'ENTER_BR',
    events: {
      'initialized': () => {
        this.ngZone.run(() => {
          this.editor = this.initControls.getEditor();
          this.editor.toolbar.hide();
        });
      },
      'focus': (e: any, editor: any) => {
        this.ngZone.run(() => this.editable(true));
      },
      'click': (e: any, editor: any) => {
        this.ngZone.run(() => this.editable(true));
      },
    }
  };

  constructor(private ngZone: NgZone) { }

  ngOnInit() {
    this.froalaEditorDefine();
  }

  froalaEditorDefine() {
    FroalaEditor.DefineIconTemplate('zmdi', '<i class="zmdi zmdi-[NAME] zmdi-hc-2x"></i>');
    FroalaEditor.DefineIcon('bold', {NAME: 'format-bold', template: 'zmdi'});
    FroalaEditor.DefineIcon('saveTextIcon', {NAME: 'check', template: 'zmdi'});
    FroalaEditor.DefineIcon('cancelTextIcon', {NAME: 'close', template: 'zmdi'});
    FroalaEditor.RegisterCommand('saveText', {
      title: 'Save',
      icon: 'saveTextIcon',
      focus: false,
      undo: false,
      refreshAfterCallback: false,

      callback: () => this.ngZone.run(() => { this.saveText(); })
    });
    FroalaEditor.RegisterCommand('cancelText', {
      title: 'Cancel',
      icon: 'cancelTextIcon',
      focus: false,
      undo: false,
      refreshAfterCallback: false,

      callback: () => this.ngZone.run(() => { this.cancelText(); })
    });
  }

  froalaInit(event): void {
    this.initControls = event;
    this.initControls.initialize();
  }

  saveText() {
    // if (this.text !== this.prevText) {}
    if (this.editor.html.get() !== this.text) {
      this.text = this.editor.html.get();
      this.changeValue.next(this.text);
      this.editor.html.set(this.text);
    }
    this.editable(false);
  }

  cancelText() {
    this.editor.html.set(this.text);
    this.editable(false);
  }

  editable(editable) {
    if (editable) {
      this.editor.toolbar.show();
    } else {
      this.editor.toolbar.hide();
      // workaround per far perdere il focus a froala
      this.editor.edit.off();
      this.editor.edit.on();
    }
    this.changeEditableMode.next(editable);
  }

}
