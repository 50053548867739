import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CognitoUser, CognitoUserAttribute} from 'amazon-cognito-identity-js';
import {AmplifyService, AuthState} from 'aws-amplify-angular/dist/src/providers';
import {Router} from '@angular/router';
import {SigninDTO} from '@auth/models/signin-dto';
import {CompleteDTO} from '@auth/models/complete-dto';
import {filter, map, shareReplay} from 'rxjs/operators';
import {SignupDTO} from '@auth/models/signup-dto';
import {ConfirmDTO} from '@auth/models/confirm-dto';
import {AuthErrorMessageService} from '@auth/auth-error-message.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  user$: Observable<CognitoUser>;
  authenticated$: Observable<boolean>;

  private authState$: Observable<AuthState>;
  private pendingAuthUser$ = new BehaviorSubject<CognitoUser>(null);

  constructor(
    private amplifyService: AmplifyService,
    private router: Router,
    private authErrorMessageService: AuthErrorMessageService
  ) {
    this.initStreams();
    this.listenAuthState();
  }

  async signIn(data: SigninDTO): Promise<CognitoUser> {
    return new Promise<CognitoUser>((resolve, reject) => {
      this.amplifyService.auth().signIn(data.username, data.password)
        .then(user => resolve(user))
        .then(() => this.router.navigate(['/dashboard']))
        .catch(reason => reject(this.authErrorMessageService.message(reason.code) || reason.message));
    });
  }

  async signUp(data: SignupDTO): Promise<CognitoUser> {
    return new Promise<CognitoUser>((resolve, reject) => {
      this.amplifyService.auth().signUp({
        username: data.username,
        password: data.password,
        attributes: {email: data.email},
        validationData: [
          new CognitoUserAttribute({Name: 'firstName', Value: data.firstName}),
          new CognitoUserAttribute({Name: 'lastName', Value: data.lastName}),
          new CognitoUserAttribute({Name: 'country', Value: data.country.code}),
          new CognitoUserAttribute({Name: 'privacyPolicyAgreed', Value: String(data.privacyPolicyAgreed)})
        ]
      })
        .then(response => resolve(response.user))
        .catch(reason => reject(reason.message));
    });
  }

  async complete(data: CompleteDTO): Promise<CognitoUser> {
    return new Promise<CognitoUser>((resolve, reject) => {
      this.amplifyService.auth().completeNewPassword(this.pendingAuthUser$.getValue(), data.password, {})
        .then(user => resolve(user))
        .catch(reason => reject(reason.message));
    });
  }

  async confirm(data: ConfirmDTO): Promise<CognitoUser> {
    return new Promise<CognitoUser>((resolve, reject) => {
      this.amplifyService.auth().confirmSignUp(data.username, data.code, {forceAliasCreation: false})
        .then(user => resolve(user))
        .then(() => this.router.navigate(['signin']))
        .catch(reason => reject(reason.message));
    });
  }

  currentUsername(): Observable<string> {
    return this.user$.pipe(
      map(user => user.getUsername())
    );
  }

  currentAccessToken(): Observable<string> {
    return this.user$.pipe(
      map(user => {
        return user.getSignInUserSession().getAccessToken().getJwtToken();
      })
    );
  }

  signOut(): Promise<void> {
    return this.amplifyService.auth().signOut();
  }

  private initStreams() {
    this.authState$ = this.amplifyService.authStateChange$.pipe(shareReplay<AuthState>(1));
    this.user$ = this.authState$.pipe(
      filter(authState => authState.state === 'signedIn'),
      map(authState => authState.user)
    );
    this.authenticated$ = this.authState$.pipe(
      map(authState => authState.state === 'signedIn')
    );
  }

  private listenAuthState() {
    this.authState$.subscribe(authState => {
      switch (authState.state) {
        case 'requireNewPassword':
          this.pendingAuthUser$.next(authState.user);
          this.router.navigate(['/complete']);
          break;
        case 'signedIn':
          if (this.pendingAuthUser$.getValue()) {
            this.router.navigate(['/dashboard']);
          }
          break;
        case 'confirmSignUp':
          this.router.navigate(['/confirm']);
          break;
      }
    });
  }
}
