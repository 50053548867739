import {Shape} from '@lib/models/shape';
import {EditShapeSceneCommand} from '@editor/models/edit-shape-scene-command';
import {Media} from '@lib/models/media';

/**
 * When you create a new command, remember to add its type into Class "EditorApiService"
 * */
export class EditSceneBackground extends EditShapeSceneCommand {
  media: Media;

  constructor(init: Partial<EditSceneBackground>) {
    super(init);
  }

  get payload(): string {
    return JSON.stringify({
      sceneId: this.sceneId,
      media: this.media
    });
  }

  apply(shape: Shape): Shape {
    try {
      const body = JSON.parse(shape.body);
      const scenes = body.scenes.map(scene => {
        return scene.id === this.sceneId ? {
          ...scene,
          media: this.media
        } : scene;
      });
      return new Shape({
        ...shape,
        body: JSON.stringify({
          ...body,
          scenes
        })
      });
    } catch (e) {
      console.error(`Can't apply ${this.constructor.name} to Shape ${shape}`);
      return shape;
    }
  }
}
