import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';
import {ShapeUiModule} from '@shape/shape-ui.module';
import {InitialsUserPipe} from '@shape/pipes/initials-user/intials-user.pipe';
import {PlayerModule} from '@lib/components/player/player.module';
import {TextEllipsesComponent} from '@shape/components/text-ellipses/text-ellipses.component';
import {RequiredMarkerDirective} from '@shape/directives/required-marker/required-marker.directive';
import {ConfirmationDialogComponent} from '@shape/components/dialog/confirmation-dialog/confirmation-dialog.component';
import {EditableTextComponent} from './components/form-fields/editable-text/editable-text.component';

const ShapeComponents = [
  TextEllipsesComponent,
  ConfirmationDialogComponent
];
const ShapePipes = [
  InitialsUserPipe
];
const ShapeDirectives = [
  RequiredMarkerDirective,
];

@NgModule({
  declarations: [
    ...ShapeComponents,
    ...ShapePipes,
    ...ShapeDirectives,
    EditableTextComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ShapeUiModule,
    PlayerModule
  ],
  entryComponents: [
    ConfirmationDialogComponent,
  ],
  exports: [
    ...ShapeComponents,
    ...ShapePipes,
    ...ShapeDirectives,
    ShapeUiModule,
    PlayerModule,
    EditableTextComponent,
  ]
})
export class ShapeModule { }





