import {from, Observable} from 'rxjs';
import {ApolloQueryResult} from 'apollo-client';
import {filter, map, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {AppsyncService} from '@shape/appsync.service';
import {Layout} from '@layout/models/layout';
import GetLayoutResponse from '@core/graphql/get-layout/get-layout-response';
import {GetLayout} from '@core/graphql/get-layout/get-layout.local';
import {UpdateLayout} from '@core/graphql/update-layout/update-layout.local';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  constructor(
    private appsyncService: AppsyncService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        let child = this.activatedRoute.firstChild;
        while (child) {
          if (child.snapshot.data.layout) {
            return child.snapshot.data.layout;
          } else if (child.firstChild) {
            child = child.firstChild;
          } else {
            return null;
          }
        }
        return null;
      }),
      filter(layout => layout !== null),
    ).subscribe(layout => this.updateLayout({type: layout}));
  }

  private layoutSubscription;

  watchLayout(): Observable<Layout> {
    return from(this.appsyncService.client).pipe(
      switchMap(client => this.fromLayoutObservableQuery(
        client.watchQuery<GetLayoutResponse>({
          query: GetLayout
        })
      )),
      map(({data}) => new Layout(data.getLayout))
    );
  }

  unwatchLayout(): void {
    if (this.layoutSubscription) {
      this.layoutSubscription.unsubscribe();
    }
  }

  updateLayout(layout: Layout): void {
    this.appsyncService.client.then(client => {
      client.mutate({
        mutation: UpdateLayout,
        variables: {...layout}
      });
    });
  }

  private fromLayoutObservableQuery(observableQuery): Observable<ApolloQueryResult<GetLayoutResponse>> {
    return new Observable<ApolloQueryResult<GetLayoutResponse>>(observer => {
      this.layoutSubscription = observableQuery.subscribe(
        result => observer.next(result),
        error => observer.error(error)
      );
      return this.layoutSubscription;
    });
  }
}
