import {Component, ChangeDetectionStrategy, Output, EventEmitter, Input} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {CompleteDTO} from '@auth/models/complete-dto';

@Component({
  selector: 'shp-complete-form',
  templateUrl: './complete-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CompleteFormComponent {
  @Input() message: string;
  @Output() submitted = new EventEmitter<CompleteDTO>();

  completeForm = this.fb.group({
    password: ['', Validators.required]
  });

  constructor(private fb: FormBuilder) { }

  complete() {
    const complete = new CompleteDTO(this.completeForm.value);
    this.submitted.emit(complete);
  }
}
