import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PlayerComponent} from '@lib/components/player/player.component';
import {SceneComponent} from '@lib/components/player/scene/scene.component';
import {FormatTimePipe} from '@lib/pipes/format-time/format-time.pipe';
import {SeekbarComponent} from '@lib/components/player/controls/default/seekbar/seekbar.component';
import {DefaultControlsComponent} from '@lib/components/player/controls/default/default-controls.component';
import {PreviewerComponent} from './previewer/previewer.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ScaleContentDirective} from '@lib/directives/scale-content.directive';
import {VolumeComponent} from '@lib/components/player/controls/default/volume/volume.component';
import {SceneThemeComponent} from '@lib/components/player/scene/themes/theme.class';
import {
  SceneThemeOrangePinkComponent,
  SceneThemeOrangePinkOpenerComponent
} from './scene/themes/theme-orange-pink/theme-orange-pink.component';
import {
  SceneThemeWhitePinkComponent,
  SceneThemeWhitePinkOpenerComponent
} from '@lib/components/player/scene/themes/theme-white-pink/theme-white-pink.component';
import {LibPlayerUiModule} from '@lib/shape-ui.module';
import {ControlsComponent} from '@lib/components/player/controls/controls.component';
import {MobileControlsComponent} from '@lib/components/player/controls/mobile/mobile-controls.component';
import {ImageRequestPipe} from '@lib/pipes/image-request/image-request.pipe';

const SceneThemeComponents = [
  SceneThemeComponent,
  SceneThemeOrangePinkComponent,
  SceneThemeOrangePinkOpenerComponent,
  SceneThemeWhitePinkComponent,
  SceneThemeWhitePinkOpenerComponent,
];
const ControlsComponents = [
  ControlsComponent,
  DefaultControlsComponent,
  SeekbarComponent,
  VolumeComponent,
  MobileControlsComponent,
];
const PlayerComponents = [
  SceneComponent,
  ...SceneThemeComponents,
  ...ControlsComponents
];

const ExportablePlayerComponents = [
  PlayerComponent,
  PreviewerComponent
];
const PlayerPipes = [
  FormatTimePipe,
  ImageRequestPipe
];
const PlayerDirectives = [
  ScaleContentDirective,
];

@NgModule({
  declarations: [
    ...ExportablePlayerComponents,
    ...PlayerComponents,
    ...PlayerPipes,
    ...PlayerDirectives,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LibPlayerUiModule,
  ],
  exports: [
    ...ExportablePlayerComponents,
    ...PlayerPipes,
    ...PlayerDirectives,
  ]
})
export class PlayerModule {
}





