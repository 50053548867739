import {ShapeErrorType} from '@shape/models/shape-error-type';

export class ShapeError {
  message: string;
  type: ShapeErrorType;

  constructor(init?: Partial<ShapeError>) {
    Object.assign(this, init);
  }
}
