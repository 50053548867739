import {Shape} from '@lib/models/shape';

export abstract class EditShapeCommand {
  createdAt: string;

  protected constructor(init?: Partial<EditShapeCommand>) {
    Object.assign(this, init);
    this.createdAt = new Date().toISOString();
  }

  abstract get payload(): string;

  abstract apply(shape: Partial<Shape>): Shape;
}
