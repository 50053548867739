import {Component} from '@angular/core';
import {SceneThemeComponent} from '../theme.class';
import {SceneService} from '@lib/components/player/scene/scene.service';

@Component({
  selector: 'shp-scene-theme-orange-pink',
  template: `
    <div class="scene--std" [attr.id]="scene.id"
         [class.scene--player]="mode === 'player'"
         [class.scene--preview]="mode === 'preview'"
    >
      <ng-container *ngIf="scene.media">
        <div *ngIf="scene.media && scene.media.type === mediaType.IMAGE"
             [style.background-image]="'url(' + (scene.media.file | imageRequest) + ')'"
             class="scene__image">
          <img #imageBackground [src]="(scene.media.file | imageRequest)"/>
        </div>
        <video #videoBackground *ngIf="scene.media.file && scene.media.type === mediaType.VIDEO"
               [src]="(scene.media.file | imageRequest)"
               class="scene__video"
               muted="muted"></video>
      </ng-container>
      <div class="scene__text" [ngStyle]="getStyle()" *ngIf="scene.text.trim().length > 0">
        <p><span [innerHTML]="scene.text"></span></p>
      </div>
    </div>
  `,
  styleUrls: ['./theme-orange-pink.component.scss']
})
export class SceneThemeOrangePinkComponent extends SceneThemeComponent {
  constructor(public sceneService: SceneService) {
    super(sceneService);
  }

  generateTimeline(opts: any) {
    this.el = `.scene--player .scene--std[id="${this.scene.id}"]`;
    super.generateTimeline(opts);
  }
  enterScene(): void {
    this.timeline.set(`${this.el} .scene__text`, {alpha: 0});
    this.timeline.to(this.el, 0.5, {right: 0, zIndex: 1}, 'begin');
    this.timeline.to(`${this.el} .scene__image`,  this.scene.duration, {scale: 1.2},  'begin+=1');
    this.timeline.to(`${this.el} .scene__text`, 0.5, {alpha: 1}, 'begin+=1');
  }
  exitScene(): void {
    if (!this.scene.last) {
      this.timeline.to(this.el, 0, {right: '-100%', zIndex: -1, delay: 0.5}, `begin+=${this.scene.duration}`);
    } else {
      this.timeline.to(this.el, 0, {right: 0, zIndex: 1}, `begin+=${this.scene.duration}`);
    }
  }
}

@Component({
  selector: 'shp-scene-theme-orange-pink-opener',
  template: `
    <div class="scene--opener" [attr.id]="scene.id"
         [class.scene--player]="mode === 'player'"
         [class.scene--preview]="mode === 'preview'"
    >
      <ng-container *ngIf="scene.media">
        <div class="scene__image" *ngIf="scene.media.type === mediaType.IMAGE"
             [style.background-image]="'url(' + (scene.media.file | imageRequest) + ')'">
          <img #imageBackground [src]="(scene.media.file | imageRequest)"/>
        </div>
        <video #videoBackground *ngIf="scene.media.type === mediaType.VIDEO"
               [src]="(scene.media.file | imageRequest)"
               class="scene__video"
               muted="muted"></video>
      </ng-container>
      <div class="scene__text" [ngStyle]="getStyle()">
        <p><span [innerHTML]="scene.text"></span></p>
      </div>
    </div>
  `,
  styleUrls: ['./theme-orange-pink.component.scss']
})
export class SceneThemeOrangePinkOpenerComponent extends SceneThemeComponent {
  constructor(public sceneService: SceneService) {
    super(sceneService);
  }

  generateTimeline(opts: any) {
    this.el = `.scene--player .scene--opener[id="${this.scene.id}"]`;
    super.generateTimeline(opts);
  }
  enterScene(): void {
    this.timeline.set(`${this.el} .scene__text`, {className: '+=scene__text--hidden'});
    this.timeline.set(`${this.el} .scene__text p`, {alpha: 0});
    this.timeline.set(this.el, {right: 0, zIndex: 1});
    this.timeline.to(`${this.el} .scene__text`, 0.5, {className: '+=scene__text--full'}, 'begin+=0.5');
    this.timeline.to(`${this.el} .scene__text`, 0.5, {className: '+=scene__text--back'}, 'begin+=1.3');
    this.timeline.to(`${this.el} .scene__text p`, 0.5, {alpha: 1}, 'begin+=2');
  }
  exitScene(): void {
    if (!this.scene.last) {
      this.timeline.to(this.el, 0, {right: '-100%', zIndex: -1, delay: 2}, `begin+=${this.scene.duration}`);
    } else {
      this.timeline.to(this.el, 0, {right: 0, zIndex: 1}, `begin+=${this.scene.duration}`);
    }
  }
  getStyle(): any {
    return {};
  }
}
