import {AfterViewInit, Component, ElementRef, Inject, Input, ViewChild} from '@angular/core';
import {PlayerService, VideoCommandVerb} from '@lib/components/player/player.service';
import {Shape} from '@lib/models/shape';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Scene} from '@lib/models/scene';
import {filter, map} from 'rxjs/operators';
import {DOCUMENT} from '@angular/common';
import {PlayerOptions} from '@lib/models/player-options';
import {OutputPlayerSize} from '@lib/directives/scale-content.directive';

@Component({
  template: `<ng-content></ng-content><div #scaleControls></div>`,
  selector: `shp-controls`
})
export class ControlsComponent implements AfterViewInit {
  @Input() shape: Shape;
  @Input() options: PlayerOptions;
  @Input() size: OutputPlayerSize;
  @ViewChild('scaleControls', {static: false}) scaleControls: ElementRef;
  currentScene$: Observable<Scene>;
  time$: Observable<number>;
  nextScene$: Observable<Scene>;
  prevScene$: Observable<Scene>;
  scenes$: Observable<Scene[]>;
  toggleBottomUp = false;
  controlsVisible$: Observable<boolean>;
  eventControlsVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  videoStatus$: Observable<VideoCommandVerb>;
  duration$: Observable<number> | BehaviorSubject<number>;
  volume$: Observable<number>;
  fullscreen$: Observable<boolean>;

  constructor(
    public player: PlayerService,
    @Inject(DOCUMENT) public document: any
  ) {
    this.prevScene$ = this.player.watchPrevScene();
    this.nextScene$ = this.player.watchNextScene();
    this.scenes$ = this.player.watchScenes();
    this.time$ = this.player.watchTime();
    this.currentScene$ = player.watchCurrentScene();
    this.videoStatus$ = this.player.watchVideoCommand().pipe(map(command => command.verb));
    this.duration$ = this.player.watchDuration();
    this.volume$ = this.player.watchControlCommand().pipe(
      filter(command => command && command.verb === 'VOLUME'),
      map(command => command.data.volume)
    );
    this.fullscreen$ = this.player.watchControlCommand().pipe(
      filter(command => command && command.verb === 'FULLSCREEN'),
      map(command => command.data.enabled)
    );
    this.controlsVisible$ =
      combineLatest(
        this.player.watchVideoCommand().pipe(
          map((command) => command.verb === 'PLAY')
        ),
        this.eventControlsVisible$
      ).pipe(
        map(([play, enter]) => {
          return !play || enter;
        })
      );
  }

  ngAfterViewInit(): void {
    if (this.size && this.scaleControls) {
      this.scaleControls.nativeElement.style.width = `${this.size.base.width}px`;
      this.scaleControls.nativeElement.style.height = `${this.size.base.height}px`;
      this.scaleControls.nativeElement.style.transform = `translate(-50%, -50%) scale(${this.size.scale})`;
    }
  }

  /** FULLSCREEN METHODS **/
  fullscreen() {
    const elem = this.document.body;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) {
      /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE/Edge */
      elem.msRequestFullscreen();
    }
  }

  /* Close fullscreen */
  exitfullscreen() {
    if (this.document.exitFullscreen) {
      this.document.exitFullscreen();
    } else if (this.document.mozCancelFullScreen) {
      /* Firefox */
      this.document.mozCancelFullScreen();
    } else if (this.document.webkitExitFullscreen) {
      /* Chrome, Safari and Opera */
      this.document.webkitExitFullscreen();
    } else if (this.document.msExitFullscreen) {
      /* IE/Edge */
      this.document.msExitFullscreen();
    }
  }
}
