export enum AspectRatioPlayer {
  sixteen_to_nine = '16:9',
  custom = 'custom',
  window = 'window'
}
export enum ThemePlayer {
  default = 'default',
  desktop = 'desktop',
  mobile = 'mobile'
}
const aspectRatioDimension: {[key: string]: {width: number; height: number; }} = {
  '16:9': {
    width: 854,
    height: 480
  },
  'window': {
    width: window.innerWidth,
    height: window.innerHeight
  }
};
export class PlayerOptions {
  /**
   * default: 0
   * 1 video plays automatically
   * 0 video doesn't play automatically
   * */
  private _autoplay: boolean;
  get autoplay(): boolean {
    return this._autoplay;
  }

  set autoplay(value) {
    this._autoplay = Number(value) === 1;
  }

  /**
   * default: 0
   * If it is specified, then the video will start from the specified time (seconds)
   * */
  private _startSeconds: number;
  get startSeconds(): number {
    return this._startSeconds;
  }
  set startSeconds(value) {
    this._startSeconds = value;
  }

  /**
   * default: 1
   * 1 recalculate the scale value while maintaining the set aspect ratio
   * 0 not scale the content, scale value fixed to 1
   * */
  private _scalable: boolean;
  get scalable(): boolean {
    return this._scalable;
  }
  set scalable(value) {
    this._scalable = Number(value) !== 0;
  }

  /**
   * default: 16:9
   * - when 'custom' is mandatory set width and height, and the aspect radio will be width/height
   * - when 'fullwindow', aspect ratio will be window width/ window height
   * */
  private _aspect_ratio: string; //  = '16:9';
  get aspect_ratio(): string {
    return this._aspect_ratio;
  }
  set aspect_ratio(value) {
    this._aspect_ratio = (Object.values(AspectRatioPlayer).includes(value)) ? value : '16:9';
    if (this.aspect_ratio !== AspectRatioPlayer.custom) {
      this._width = aspectRatioDimension[this._aspect_ratio].width;
      this._height = aspectRatioDimension[this._aspect_ratio].height;
    }
  }

  /**
   * default width: 854 height: 480,
   * this setting will be used only if the aspect_ratio value is 'custom', otherwise width and height will be avoid
   * */
  private _width: number;
  get width(): number {
    return this._width;
  }
  set width(value) {
    if (this.aspect_ratio === AspectRatioPlayer.custom) {
      this._width = (value && Number(value)) ? Number(value) : 854;
    }
  }
  private _height: number;
  get height(): number {
    return this._height;
  }
  set height(value) {
    if (this.aspect_ratio === AspectRatioPlayer.custom) {
      this._height = (value && Number(value)) ? Number(value) : 480;
    }
  }

  /**
   * default
   * this value is able to defined the theme of controls
   * */
  private _theme: string;
  get theme(): string {
    return this._theme;
  }
  set theme(value) {
    this._theme = (Object.values(ThemePlayer).includes(value)) ? value : ThemePlayer.default;
  }

  /**
   * default: 1
   * 1 show overlay
   * 0 hide overlay
   * */
  private _overlay: boolean;
  get overlay(): boolean {
    return this._overlay;
  }
  set overlay(value) {
    this._overlay = Number(value) !== 0;
  }

  /**
   * default 1
   * 1 show title inside the overlay
   * 0 hide title inside the overlay
   * */
  private _title: boolean;
  get title(): boolean {
    return this._title;
  }
  set title(value) {
    this._title = Number(value) !== 0;
  }

  /**
   * default 1
   * 1 show duration inside the overlay
   * 0 hide duration inside the overlay
   * */
  private _duration: boolean;
  get duration(): boolean {
    return this._duration;
  }
  set duration(value) {
    this._duration = Number(value) !== 0;
  }

  /**
   * default arrow
   * 1 show arrow (next/back) inside the overlay
   * 0 hide arrow (next/back) inside the overlay
   * */
  private _arrows: boolean;
  get arrows(): boolean {
    return this._arrows;
  }
  set arrows(value) {
    this._arrows = Number(value) !== 0;
  }


  constructor(init?: Partial<any>) {
    if (!init) {
      init = {};
    }
    this.parse(init);
  }

  getQueryParams(): {[key: string]: string} {
    const queryParams = {};
    if (!this.overlay) {
      queryParams['overlay'] = '0';
    }
    if (this.autoplay) {
      queryParams['autoplay'] = '1';
    }
    if (this.theme === ThemePlayer.mobile) {
      queryParams['theme'] = 'mobile';
    }
    return queryParams;
  }

  getQueryString(): string {
    const queryParams = [];
    if (!this.overlay) {
      queryParams.push('overlay=0');
    }
    if (this.autoplay) {
      queryParams.push('autoplay=1');
    }
    if (this.theme === ThemePlayer.mobile) {
      queryParams.push('theme=mobile');
    }
    return queryParams.join('&');
  }

  parse(init): void {
    this.autoplay = init.autoplay;
    this.scalable = init.scalable;
    this.aspect_ratio = init.aspect_ratio;
    this.width = init.width;
    this.height = init.height;
    this.theme = init.theme;
    this.overlay = init.overlay;
    this.title = init.title;
    this.duration = init.duration;
    this.arrows = init.arrows;
    this.startSeconds = init.startSeconds;
  }
}
