import gql from 'graphql-tag';

const query = gql`
  query ListMyShapes($filter: ModelShapeFilterInput) {
    listShapes(limit: 1000, filter: $filter) {
      __typename
      items {
        __typename
        id
        title
        status
        statusInfo
        cover {
          __typename
          bucket
          region
          key
        }
        editCommands(limit: 1000) {
          __typename
          items {
            __typename
            type
            payload
            createdAt
          }
          nextToken
        }
        body
        createdAt
        updatedAt
      }
      nextToken
    }
  }`;

export {query as ListMyShapes};
