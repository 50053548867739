import {ChangeDetectionStrategy, Component, ElementRef, Inject, OnInit} from '@angular/core';
import {PlayerService} from '@lib/components/player/player.service';
import {DOCUMENT} from '@angular/common';
import {ControlsComponent} from '@lib/components/player/controls/controls.component';

@Component({
  selector: 'shp-player-default-controls',
  templateUrl: './default-controls.component.html',
  styleUrls: ['./default-controls.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultControlsComponent extends ControlsComponent {
  constructor(
    @Inject(DOCUMENT) public document: any,
    public playerService: PlayerService,
    private el: ElementRef
  ) {
    super(playerService, document);
  }
}
