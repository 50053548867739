import {Pipe, PipeTransform} from '@angular/core';
import {S3Object} from '@lib/models/s3-object';
import {AspectRatio} from '@lib/models/aspect-ratio';

@Pipe({name: 'imageRequest'})
export class ImageRequestPipe implements PipeTransform {

  private CloudFrontUrl = 'https://d2htqgqsem45wj.cloudfront.net';

  transform(media: S3Object, width?: number, height?: number, aspectRatio?: AspectRatio): string {

    const options: { [k: string]: any } = {};
    const ratio = aspectRatio ?
      'RATIO_16_9' ? (16 / 9) :
        'RATIO_4_3' ? (4 / 3) :
          'SQUARE_RATIO' ? 1 :
            (16 / 9) :
      (16 / 9);

    if (height && width) {
      options.resize = {
        width: width,
        height: height
      };
    } else if (width) {
      options.resize = {
        width: width,
        height: width / ratio
      };
    } else if (height) {
      options.resize = {
        width: height / ratio,
        height: height
      };
    }
    const imageRequest = JSON.stringify({
      bucket: media.bucket,
      key: media.key,
      edits: options
    });
    return `${this.CloudFrontUrl}/${btoa(imageRequest)}`;
  }
}
