import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ShapeError} from '@shape/models/shape-error';
import {CognitoUser} from 'amazon-cognito-identity-js';
import {NotificationApiService} from '@app/notifications/notification-api.service';
import {NotificationStatus} from '@app/notifications/models/notification-status.enum';
import {MatDrawer} from '@angular/material';
import {Notification} from '@app/notifications/models/notification';

@Component({
  selector: 'shp-default-layout',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultLayoutComponent implements OnChanges {
  @Input() layout: any;
  @Input() user: CognitoUser;
  @Input() error: ShapeError;
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  @Input() notifications: Notification[] = [];
  @Input() numNewNotifications = 0;
  constructor(
    private notificationApi: NotificationApiService
  ) { }

  ngOnChanges(changes: SimpleChanges): void { }

  async openRightPanel(drawer: MatDrawer): Promise<void> {
    await drawer.open();
    for (const updateNotification of this.notifications.filter(not => not.status === NotificationStatus.NEW)) {
      updateNotification.status = NotificationStatus.UNREAD;
      await this.notificationApi.updateUserNotification(updateNotification);
    }
  }

  signOut() {
    this.logout.emit();
  }
}
