import gql from 'graphql-tag';

const mutation = gql`
mutation CreateEditShapeCommand($input: CreateEditShapeCommandInput!) {
  createEditShapeCommand(input: $input) {
    __typename
    id
    shape {
      __typename
      id
    }
    type
    payload
    createdAt
  }
}`;

export { mutation as CreateEditShapeCommand };
