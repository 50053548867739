import {ElementRef, Injectable, OnDestroy} from '@angular/core';
import {fromEvent, Subject} from 'rxjs';
import {TimelineMax} from 'gsap/all';
import {first} from 'rxjs/operators';
import {PlayerService} from '@lib/components/player/player.service';
import {SceneThemeComponent} from '@lib/components/player/scene/themes/theme.class';
import {Scene} from '@lib/models/scene';
import {MediaType} from '@lib/models/media-type';

export type modeType = 'preview' | 'player';

@Injectable()
export class SceneService implements OnDestroy {
  sceneReady$ = new Subject<any>();
  parentTimeline: TimelineMax;
  theme: SceneThemeComponent;
  timelinePrepared = false;
  active = false;
  // add video
  private _video: ElementRef;
  private _image: ElementRef;
  private _mode: modeType;
  private _id: string = null;
  private _scene: Scene = null;

  constructor(private playerService: PlayerService) {}

  set id(id: string) {
    this._id = id;
    this.timelinePrepared = false;
  }
  set video(video: ElementRef) {
    if (video) {
      this._video = video;
      fromEvent(video.nativeElement, 'loadeddata').pipe(
        first()
      ).subscribe((data) => this.loadedAssets(data));
    }
  }
  set image(image: ElementRef) {
    if (image) {
      this._image = image;
      fromEvent(image.nativeElement, 'load').pipe(
        first()
      ).subscribe((data) => this.loadedAssets(data));
      fromEvent(image.nativeElement, 'error').pipe(
        first()
      ).subscribe((data) => this.loadedAssets(data));
    }
  }
  set mode(mode: modeType) {
    this._mode = mode;
    if (mode === 'player') {
      this.playerService.watchVideoCommand().subscribe((command) => {
        switch (command.verb) {
          case 'PLAY': return this.resume();
          case 'PAUSE': return this.stop();
        }
      });
      this.playerService.watchControlCommand().subscribe((command) => {
        switch (command.verb) {
          case 'GO_TO_PREV': break;
          case 'GO_TO_NEXT': break;
          case 'SEEK':
            this.setIsActiveScene();
            return this.seek(command.data.time);
        }
      });
    }
  }
  get mode() {
    return this._mode;
  }
  set scene(scene: Scene) {
    scene.duration = (scene.duration < 5) ? 5 : scene.duration;
    this._scene = new Scene(scene);
    if (!scene.media) {
      this.loadedAssets(scene);
    }
  }
  get scene() {
    return this._scene;
  }

  ngOnDestroy(): void {
    this.sceneReady$.complete();
  }

  loadedAssets(data): void {
    this.preparingTimeline({scene: this._scene});
  }
  preparingTimeline(data): void {
    if (!this.timelinePrepared) {
      if (this._mode === 'player' && this.parentTimeline !== null) {
        this.theme.generateTimeline({
          onStart: this.onStartTimeline.bind(this),
          onUpdate: this.onUpdateTimeline.bind(this),
          onComplete: this.onCompleteTimeline.bind(this)
        });
        this.parentTimeline.add(this.theme.timeline, `masterTimelineBegin+=${(this._scene.time.start / 1000) - 0.2}`);
      }
      this.preparedTimeline({scene: this._scene});
    }
  }
  preparedTimeline(data): void {
    if (!this.timelinePrepared) {
      this.timelinePrepared = true;
      this.sceneReady$.next(this._scene);
    }
  }

  /** ===================================================== */
  /** ================== VIDEO METHODS ==================== */
  /** ===================================================== */
  private play(): void {
    if (this._scene.media.type === MediaType.VIDEO && this._video && this._video.nativeElement) {
      this._video.nativeElement.currentTime = 0;
      this._video.nativeElement.loop = true;
      this._video.nativeElement.muted = 'muted';
      this._video.nativeElement.volume = 0;
    }
    if (this.active && this._scene.media.type === MediaType.VIDEO && this._video && this._video.nativeElement) {
      if (this._video.nativeElement.paused) {
        this._video.nativeElement.play();
      }
    }
  }
  private resume(): void {
    if (this.active && this._scene.media.type === MediaType.VIDEO && this._video && this._video.nativeElement) {
      if (this._video.nativeElement.paused) {
        this._video.nativeElement.play();
      }
    }
  }

  private stop(): void {
    if (this._scene.media.type === MediaType.VIDEO && this._video && this._video.nativeElement) {
      this._video.nativeElement.pause();
    }
  }

  private seek(time): void {
    console.log(time);
    if (time && this.active && this._scene.media.type === MediaType.VIDEO && this._video && this._video.nativeElement) {
      const currentTime = (time - this._scene.time.start) / 1000;
      this._video.nativeElement.currentTime = currentTime % this._video.nativeElement.duration;
    }
  }
  /** ===================================================== */
  /** ================== TIMELINE METHODS ================= */
  /** ===================================================== */
  private onStartTimeline(): void {
    if (this._mode === 'player' && this.parentTimeline !== null) {
      this.setIsActiveScene();
      this.play();
    }
  }
  private onUpdateTimeline(): void {
    if (this._mode === 'player' && this.parentTimeline !== null) {
      this.setIsActiveScene();
      this.resume();
    }
  }
  private onCompleteTimeline(): void {
    if (this._mode === 'player' && this.parentTimeline !== null) {
      this.setIsActiveScene();
      this.stop();
    }
  }

  private setIsActiveScene(): void {
    const masterTimeline = this.parentTimeline.time() * 1000; // transform second in millisecond
    this.active = this._scene.time.start <= masterTimeline && masterTimeline < this._scene.time.end;
  }
}
