import {AfterViewInit, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {MatTooltip} from '@angular/material';

@Component({
  selector: '[shp-text-ellipses]',
  template: `<span [matTooltip]="text" #tooltip>{{text}}</span>`,
})
export class TextEllipsesComponent implements AfterViewInit {
  @Input() text = '';
  @ViewChild(MatTooltip, {static: false}) tooltip: MatTooltip;

  constructor(private el: ElementRef) {}
  ngAfterViewInit(): void {
    if (this.el.nativeElement.scrollWidth <= this.el.nativeElement.offsetWidth) {
      this.tooltip.disabled = true;
    }
  }

}
